import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

export function renderHeading({ closeModal, history }) {
  return (
    <div className="modal-header mb-4 d-flex justify-content-between">
      <FontAwesomeIcon
        onClick={history.goBack}
        className="modal-close-btn"
        icon={faArrowLeft}
        fixedWidth
      />
      <FontAwesomeIcon
        onClick={closeModal}
        className="modal-close-btn"
        icon={faTimes}
        fixedWidth
      />
    </div>
  );
}
