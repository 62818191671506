import React from 'react';
import logoBTFamily from 'assets/images/part-bt-family-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row align-items-end text-lg-left">
            <div className="col-12 col-lg-7 flex">
              <img
                className="footer__bt-brands-logo"
                src={logoBTFamily}
                alt="Part of the Brilliant Travels Family"
              />
              <a
                className="btn-outline-light btn footer__bt-brands-button text-decoration-none text-white ml-3"
                href="https://www.brillianttravels.com.au/our-family"
                target="_blank"
                rel="noopener noreferrer"
                title="Our Brilliant Brands"
              >
                Our Brilliant Brands
                <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
              </a>
            </div>
            <div className="col-12 col-lg-5 footer-utilities mt-4 mt-lg-0">
              <div className="text-center text-lg-right">
                <a
                  href="https://www.kelsian.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Privacy Policy"
                >
                  Privacy Policy
                </a>
                Copyright &copy; {new Date().getFullYear()} Kelsian Group
                Limited
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
