import {
  createBookingAndReservation,
  cancelBooking,
} from 'api/quick_travel_api/bookings';
import { filter, remove, find, includes, isEmpty } from 'lodash';
import Config from 'config/config';
import moment from 'moment';
import {
  serviceTimeFormat,
  standardDateFormat,
  sortTimeFormat,
} from 'utils/time_formats';

export const handleBookingUpdates = function ({ values }) {
  return (dispatch, getState) => {
    if (values.isOrganisationManager) {
      const bookings = filter(getState().qt_endpoint.bookings, (booking) => {
        return (
          includes(
            getState().qt_endpoint.services[values.bookingService].booking_ids,
            booking.id
          ) && booking.state === 'active'
        );
      });

      // list of clients associated with active bookings for the selected service
      const existingBookingClients = bookings.map((booking) => {
        return booking.client_id;
      });

      if (values.bookingClients) {
        values.bookingClients.forEach((clientId) => {
          if (includes(existingBookingClients, clientId)) {
            // removing client from existingBookingClients array so as to not delete its booking
            remove(existingBookingClients, (n) => n === clientId);
          } else {
            // create new booking for clients that don't already have one
            dispatch(createBookingAndReservation({ values, clientId }));
          }
        });
      }

      // iterate over remaining existingBookingClients and cancel bookings associated with them
      let booking;
      existingBookingClients.forEach((clientId) => {
        booking = find(bookings, (booking) => {
          return booking.client_id === clientId;
        });
        dispatch(cancelBooking({ bookingId: booking.id, clientId }));
      });
    } else {
      dispatch(
        createBookingAndReservation({
          values,
          clientId: values.bookingClients[0],
        })
      );
    }
  };
};

export const isBookingStub = function (obj) {
  return isEmpty(obj?.reservations_attributes);
};

export const tripDetailsFromBooking = function (booking) {
  if (isBookingStub(booking)) {
    return null;
  }
  const reservationAttrs = booking.reservations_attributes[0];
  const segment = find(
    Config.segments,
    (segment) =>
      segment.from_stop_id === reservationAttrs.from_route_stop_attributes.id &&
      segment.to_stop_id.includes(reservationAttrs.to_route_stop_attributes.id)
  );
  const departureDate = moment(booking.first_travel_date._value).format(
    standardDateFormat
  );
  const baseDepartureTime = moment(reservationAttrs.start_time).utc();
  const departureTime = baseDepartureTime.format(serviceTimeFormat);
  const departureTimeSort = baseDepartureTime.format(sortTimeFormat);
  return { segment, departureTime, departureDate, departureTimeSort };
};

export const bookingBelongsTo = function ({ booking, clientId }) {
  if (!clientId || isEmpty(booking)) {
    return undefined;
  }
  return (
    parseInt(booking.client_id) === parseInt(clientId) ||
    parseInt(booking.client?.id) === parseInt(clientId)
  );
};
