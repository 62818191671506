import React from 'react';
import { pick, isEmpty } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimes,
  faInfoCircle,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';

import { useServiceBookingsIds } from 'redux/selectors/services';
import { finished } from 'redux/actions/qt_endpoint';

import { ModalLink } from 'react-router-modal';

export function PassengerList({ service, onSelectionChange, selected }) {
  // Fetch booking information
  const { activeServiceBookingIds, allBookings } = useServiceBookingsIds(
    service
  );

  const serviceBookings = pick(allBookings, activeServiceBookingIds);

  const bookingFetchComplete = useSelector((state) => {
    return state.qt_endpoint.api.bookings[service.date] === finished;
  });

  const clients = useSelector((state) => state.qt_endpoint.clients);

  const currentlySelected = (id) => selected[id] || false;
  const toggleSelected = (id) => {
    const newSelected = { ...selected, [id]: !currentlySelected(id) };

    onSelectionChange(newSelected);
  };
  const selectedBookingIds = () => {
    return Object.keys(selected).filter((key) => selected[key]);
  };

  const bookingClientName = (booking) => {
    const client = clients[booking.client_id];

    if (!client) {
      return null;
    }

    return `${client.first_name} ${client.last_name} `;
  };

  const renderUnpaid = (booking) => {
    if (booking.balance_in_cents > 0) {
      return <div>Booking Unpaid</div>;
    }
  };

  // Handle loading state
  if (isEmpty(serviceBookings)) {
    if (bookingFetchComplete) {
      return (
        <div>
          <h3 className="font-weight-normal border-bottom pb-2 mb-3">
            Travelling Passengers
          </h3>
          <div className="my-4">No Organisation Passengers</div>
        </div>
      );
    }

    return (
      <div>
        <h3 className="font-weight-normal border-bottom pb-2 mb-3">
          Travelling Passengers
        </h3>
        <div className="my-4">
          <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
          Loading Service Bookings&hellip;
        </div>
      </div>
    );
  }

  return (
    <div className="passenger-list">
      <h3 className="font-weight-normal border-bottom pb-2 mb-3">
        Travelling Passengers (
        {activeServiceBookingIds.length - selectedBookingIds().length})
      </h3>
      <ul className="passenger-list-ul mb-5">
        {activeServiceBookingIds.map((id) => {
          const booking = serviceBookings[id];
          if (!booking) {
            return <div>Booking #{id} Not Loaded </div>;
          }
          return (
            <li
              key={`booking-${id}`}
              className={classNames({ selected: currentlySelected(id) })}
              onClick={() => toggleSelected(id)}
              data-testid={`li-booking-${id}`}
            >
              {currentlySelected(id) ? (
                <FontAwesomeIcon
                  className="text-success"
                  icon={faCheckCircle}
                  fixedWidth
                />
              ) : (
                <FontAwesomeIcon
                  className="text-danger"
                  icon={faTimes}
                  fixedWidth
                />
              )}
              <div className="booking-details">
                {bookingClientName(booking)}
                <span>{booking.reference}</span>
              </div>
              <div className="booking-actions">
                <div className="float-right">
                  <ModalLink path={`/dashboard/bookings/${id}`}>
                    <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                  </ModalLink>
                </div>
                {renderUnpaid(booking)}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
