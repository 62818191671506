import { combineReducers } from 'redux';

import LoginReducer from './login';

import QtEndpointReducer from './qt_endpoint';

export default combineReducers({
  login: LoginReducer,
  qt_endpoint: QtEndpointReducer,
});
