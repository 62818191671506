import React from 'react';
import { useRouteStop } from 'redux/selectors/route_stops';
import { serviceTimeFormat } from 'utils/time_formats';
import moment from 'moment';

export function BookingService({ service }) {
  const routeStop = useRouteStop(service?.from_route_stop_id);
  if (service == null) {
    return null;
  }

  return (
    <>
      Departing {routeStop?.name} -{' '}
      {moment(service.departure_time)
        .utcOffset(service.departure_time)
        .format(serviceTimeFormat)}
      <br />
    </>
  );
}

export default BookingService;
