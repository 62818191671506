import Config from 'config/config';

const stripTrailingSlash = (str) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

const startWithSlash = (str) => {
  return str.startsWith('/') ? str : `/${str}`;
};

export const generateImageUrl = (input) => {
  if (!input) {
    return null;
  }

  if (input?.startsWith('http')) {
    return input;
  }
  return stripTrailingSlash(Config.quickTravelURL) + startWithSlash(input);
};
