import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Day } from 'components/day';
import { useSegmentName } from 'redux/selectors/segments';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import moment from 'moment';
import { reverseDateFormat } from 'utils/time_formats';

import QuickTravelApi from 'api/quick_travel_api';

export function DayCarousel({ segment, days, date }) {
  const [startDate, setStartDate] = useState(date);
  const [dates, setDates] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setStartDate(date);
  }, [date]);

  useEffect(() => {
    let tempDates = [];
    for (let i = 0; i < days; i++) {
      tempDates.push(moment(startDate).add(i, 'days'));
    }
    setDates(tempDates);
  }, [days, startDate]);

  useEffect(() => {
    dispatch(QuickTravelApi.Bookings.index({ date: startDate, days }));
  }, [startDate, days, dispatch]);

  const previousPage = (e) => {
    setStartDate(moment(startDate).add(-days, 'days'));
  };

  const nextPage = (e) => {
    setStartDate(moment(startDate).add(days, 'days'));
  };

  const segmentName = useSegmentName(segment);

  return (
    <>
      <h2 className="route-title">{segmentName}</h2>
      <div className="day-carousel">
        <button
          className="carousel-navigate-btn"
          data-testid="previous-button"
          type="button"
          onClick={previousPage}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        {dates.map((date) => {
          return (
            <Day
              key={`Date--${date.format(reverseDateFormat)}`}
              {...{ segment, date }}
            />
          );
        })}
        <button
          className="carousel-navigate-btn"
          data-testid="next-button"
          type="button"
          onClick={nextPage}
        >
          <FontAwesomeIcon icon={faChevronLeft} rotation={180} />
        </button>
      </div>
    </>
  );
}
