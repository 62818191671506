import { useSelector } from 'react-redux';

export function useRouteStop(routeStopId) {
  const routeStop = useSelector((state) => {
    if (
      state?.qt_endpoint?.routeStops == null ||
      state.qt_endpoint.routeStops[routeStopId] === undefined
    )
      return undefined;
    else return state.qt_endpoint.routeStops[routeStopId];
  });

  return routeStop;
}
