import React from 'react';

import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { login as loginAction } from 'redux/actions/login';
import AuthServiceApi from 'api/auth_service';

import LoginForm, { OktaLoginButton } from 'components/login';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import Config from 'config/config';

export function LoginPage({ location, ..._props }) {
  const dispatch = useDispatch();
  const { authenticatedUser } = useSelector((state) => state.login);

  const oktaButtonEnabled =
    process.env.REACT_APP_OKTA_BUTTON_ENABLED === 'true';

  if (!isEmpty(authenticatedUser)) {
    const { from } = location.state || { from: { pathname: '/dashboard' } };
    return <Redirect to={from} />;
  }

  return (
    <>
      <div className="container">
        <div className="login-screen">
          <Header />
          <LoginForm
            login={AuthServiceApi.login}
            onLogin={(user) => dispatch(loginAction(user))}
          />
          <a className="mt-2 text-right" href={Config.passwordResetUrl}>
            Forgot your password?
          </a>
          {oktaButtonEnabled && <OktaLoginButton />}
        </div>
      </div>
      <Footer />
    </>
  );
}
