import Rollbar from 'rollbar';

function initRollbar() {
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    },
    version: process.env.VERSION,
    enabled: process.env.REACT_APP_ROLLBAR_ENABLED,
  };

  return new Rollbar(rollbarConfig);
}

export { initRollbar };
