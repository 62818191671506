import { ErrorMessage } from 'formik';
import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import { standardDateFormat } from 'utils/time_formats';
import moment from 'moment';

export const SingleDatePickerField = ({
  numberOfMonths,
  field,
  form,
  onDateChange,
  displayFormat,
  hideKeyboardShortcutsPanel,
  labelName,
  id,
  disabled,
}) => {
  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(field.value ? moment(field.value, standardDateFormat) : null);
  }, [field]);

  return (
    <div className="single-date-picker-field-container">
      <label htmlFor={field.name}>{labelName}</label>
      <ErrorMessage
        className="error-feedback ml-3"
        name={field.name}
        component="span"
        data-testid={`${id}-error`}
      />
      <SingleDatePicker
        numberOfMonths={numberOfMonths || 1}
        date={date}
        onDateChange={(option) => {
          form.setFieldValue(field.name, option.format(standardDateFormat));
          setDate(option);
          if (onDateChange) {
            onDateChange(option);
          }
        }}
        onFocusChange={(e) => setFocused(e.focused)}
        focused={focused}
        displayFormat={displayFormat}
        hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel || true}
        disabled={disabled}
      />
    </div>
  );
};
