import moment from 'moment';

const serviceDepartureMoment = (service, trip) => {
  const tripDepartureTime = moment(trip?.departure_time);

  const serviceDateComponents = service.date.split('-');

  return moment()
    .year(serviceDateComponents[0])
    .month(parseInt(serviceDateComponents[1]) - 1)
    .date(serviceDateComponents[2])
    .hour(tripDepartureTime.utc().hour())
    .minute(tripDepartureTime.utc().minute());
};

export const serviceWithinWindow = ({ service, trip, limit }) => {
  return (
    serviceDepartureMoment(service, trip).subtract(limit, 'minutes') > moment()
  );
};
