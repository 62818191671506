import { useSelector } from 'react-redux';

export function useSegmentName(segment) {
  const segmentName = useSelector((state) => {
    const routeStops = state.qt_endpoint.routeStops;
    if (!routeStops) {
      return undefined;
    }

    const from_route_stop = routeStops[segment.from_stop_id];
    const to_route_stop = routeStops[segment.to_stop_id.at(-1)];

    if (!from_route_stop || !to_route_stop) {
      return undefined;
    }

    return `${from_route_stop.name} to ${to_route_stop.name}`;
  });

  return segmentName;
}

export function useServiceName(service) {
  const segmentName = useSelector((state) => {
    const routeStops = state.qt_endpoint.routeStops;

    if (!routeStops || !service) {
      return undefined;
    }

    const from_route_stop = routeStops[service.from_route_stop_id];
    const to_route_stop = routeStops[service.to_route_stop_id];

    if (!from_route_stop || !to_route_stop) {
      return undefined;
    }

    return `${from_route_stop.name} to ${to_route_stop.name}`;
  });

  return segmentName;
}

export function useReservationServiceName(reservation) {
  const segmentName = useSelector((state) => {
    const routeStops = state.qt_endpoint.routeStops;

    if (!routeStops || !reservation) {
      return undefined;
    }

    const from_route_stop =
      routeStops[reservation.from_route_stop_attributes.id];
    const to_route_stop = routeStops[reservation.to_route_stop_attributes.id];

    if (!from_route_stop || !to_route_stop) {
      return undefined;
    }

    return `${from_route_stop.name} to ${to_route_stop.name}`;
  });

  return segmentName;
}

export function useSegments(segments) {
  return useSelector((state) => {
    const routeStops = state.qt_endpoint.routeStops;

    if (!routeStops) {
      return [];
    }

    return segments.map((segment) => {
      const from_route_stop = routeStops[segment.from_stop_id];
      const to_route_stop = routeStops[segment.to_stop_id];

      if (from_route_stop && !to_route_stop) {
        const name = `${from_route_stop.name} to Rottnest Island`;

        return { ...segment, name };
      }

      if (!from_route_stop || !to_route_stop) {
        return undefined;
      }

      const name = `${from_route_stop.name} to ${to_route_stop.name}`;

      return { ...segment, name };
    });
  });
}
