import { createReducer } from '@reduxjs/toolkit';
import {
  setServices,
  setEntities,
  resetEntities,
  fetchBookingsStarted,
  fetchBookingsFinished,
  fetchBookingsError,
  fetchServicesStarted,
  fetchServicesFinished,
  fetchServicesError,
  addBookingToService,
  started,
  finished,
  errorStatus,
  setBookingCancelled,
} from '../actions/qt_endpoint';

import { logout } from 'redux/actions/login';

import { map, forEach, cloneDeep, merge, find } from 'lodash';

import moment from 'moment';
import { reverseDateFormat } from 'utils/time_formats';

export const initialState = {
  services: {},
  bookings: {},
  routes: {},
  clients: {},
  api: {
    bookings: {},
    services: {},
  },
};

const QtEndpointReducer = createReducer(initialState, {
  //TODO - This is stubbed out and not final.
  [setServices]: (state, action) => {
    const { routeId, date, services } = action.payload;

    const servicesIDs = map(action.payload.services, 'id');

    const annotatedServices = cloneDeep(services);
    forEach(annotatedServices, (service) => (service.routeId = routeId));

    state.services = { ...state.services, ...annotatedServices };

    const route = state.routes[routeId] || {};

    if (!route.dates) {
      route.dates = {};
    }

    route.dates[date] = servicesIDs;
    state.routes[routeId] = route;
  },
  [setEntities]: (state, action) => {
    const { entities } = action.payload;
    merge(state, entities);
  },
  [fetchBookingsStarted]: (state, action) => {
    const { date, days, clientId } = action.payload;
    if (clientId) {
      merge(state.api.bookings, statusByClientId(clientId, started));
    }
    if (date && days) {
      merge(state.api.bookings, statusByDate(date, days, started));
    }
  },
  [fetchBookingsFinished]: (state, action) => {
    const { date, days, clientId } = action.payload;
    if (clientId) {
      merge(state.api.bookings, statusByClientId(clientId, finished));
    }
    if (date && days) {
      merge(state.api.bookings, statusByDate(date, days, finished));
    }
  },
  [fetchBookingsError]: (state, action) => {
    const { date, days, clientId } = action.payload;
    if (clientId) {
      merge(state.api.bookings, statusByClientId(clientId, errorStatus));
    }
    if (date && days) {
      merge(state.api.bookings, statusByDate(date, days, errorStatus));
    }
  },
  [fetchServicesStarted]: (state, action) => {
    const { date, routeId } = action.payload;
    merge(state.api.services, serviceStatus(date, routeId, started));
  },
  [fetchServicesFinished]: (state, action) => {
    const { date, routeId } = action.payload;
    merge(state.api.services, serviceStatus(date, routeId, finished));
  },
  [fetchServicesError]: (state, action) => {
    const { date, routeId } = action.payload;
    merge(state.api.services, serviceStatus(date, routeId, errorStatus));
  },
  [addBookingToService]: (state, action) => {
    const { serviceId, bookingId } = action.payload;

    const service = state.services[serviceId] || {};
    const booking_ids = service?.booking_ids || [];

    service.booking_ids = [...booking_ids, bookingId];
    if (Number.isInteger(service.booked)) {
      service.booked += 1;
    }
    state.services[serviceId] = service;
  },
  [resetEntities]: () => initialState,
  [logout]: () => initialState,
  [setBookingCancelled]: (state, action) => {
    const { bookingId } = action.payload;
    const booking = state.bookings[bookingId];

    if (booking) {
      booking.state = 'cancelled';
    }

    const service = find(state.services, (service) => {
      return service.booking_ids.includes(parseInt(bookingId));
    });

    if (Number.isInteger(service.booked)) {
      service.booked -= 1;
    }
  },
});

const statusByDate = (dateString, days, status) => {
  const statuses = {};
  for (var i = 0; i < days; i++) {
    const newDateString = moment(dateString)
      .add(i, 'days')
      .format(reverseDateFormat);

    statuses[newDateString] = status;
  }

  return statuses;
};

const statusByClientId = (clientId, status) => {
  return { [clientId]: status };
};

const serviceStatus = (date, routeId, status) => {
  return { [routeId]: { [date]: status } };
};

export default QtEndpointReducer;
