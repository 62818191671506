import Config from 'config/config';

import { isEmpty } from 'lodash';

import { setEntities } from 'redux/actions/qt_endpoint';
import { authorizedFetch, reportAndDisplayError } from './utils';

import { normalize, organisation } from './schemas';

const show = function ({ organisationId }) {
  return (dispatch, getState) => {
    const options = {
      method: 'GET',
    };

    const url = new URL(
      `${Config.quickTravelURL}/api/organisations/${organisationId}`
    );

    return authorizedFetch(dispatch, getState, {
      url,
      options,
    })
      .then((jsonData) => {
        if (isEmpty(jsonData)) {
          return { entities: { organisations: {} } };
        }

        return normalize(jsonData, organisation);
      })
      .then((normalizedData) => {
        const entities = normalizedData.entities;
        dispatch(setEntities({ entities }));
      })
      .catch((error) => {
        dispatch(reportAndDisplayError(error));
      });
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { show };
