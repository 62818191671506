console.log('process.env', process.env);
let Config = {
  authServiceURL: process.env.REACT_APP_AUTH_SERVICE_URL,
  quickTravelURL:
    process.env.REACT_APP_QUICK_TRAVEL_URL || window.location.origin,
  barcodeServiceURL: process.env.REACT_APP_BARCODE_SERVICE_URL,
  routeIDs: process.env.REACT_APP_ROUTE_IDS.split(','),
  accessKey: process.env.REACT_APP_QT_ACCESS_KEY,
  segments: [
    {
      id: 'fremantle_to_rottnest',
      from_stop_id: 74,
      to_stop_id: [75],
      route_id: 8,
    },
    {
      id: 'rottnest_to_fremantle',
      from_stop_id: 76,
      to_stop_id: [77],
      route_id: 9,
    },
  ],
  onAccountPaymentTypeId: 3,
  tillId: 18,
  creditCards: [
    { name: 'MasterCard', paymentTypeId: 14 },
    { name: 'Visa', paymentTypeId: 2 },
    {
      name: 'American Express',
      paymentTypeId: 5,
    },
  ],
  braintreeSurcharge: 0.0125,
  passwordResetUrl: process.env.REACT_APP_PASSWORD_RESET_URL,
  cancelCutoffMinutes: 60,
  bookingCutoffMinutes: 5,
};

export default Config;
