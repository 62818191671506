import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

function initDataDog() {
  if (process.env.REACT_APP_DATADOG_ENABLED) {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: process.env.REACT_APP_DATADOG_SERVICE,
      env: process.env.REACT_APP_DATADOG_SERVICE,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      version: process.env.VERSION,
    });

    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: process.env.REACT_APP_DATADOG_SERVICE,
      env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
      version: process.env.VERSION,
      sampleRate: 100,
      trackInteractions: true,
    });
  }
}
export { initDataDog };
