import { normalize, schema } from 'normalizr';

const routeStop = new schema.Entity('routeStops');
const route = new schema.Entity('routes', {
  route_stops: [routeStop],
});

const service = new schema.Entity('services');
const servicesResult = new schema.Entity('serviceApiResult', {
  services: [service],
});

const booking = new schema.Entity('bookings');
const trip = new schema.Entity('trips');

const client = new schema.Entity('clients', {}, { idAttribute: 'client_id' });
const organisation = new schema.Entity('organisations', {
  staff: [client],
});

export {
  normalize,
  servicesResult,
  booking,
  route,
  client,
  organisation,
  trip,
};
