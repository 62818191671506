import Config from 'config/config';

import {
  authorizedFetch,
  generateClientErrorMessage,
  handleErrorStatus,
} from './utils';

import { createOrUpdateToast } from 'api/quick_travel_api/utils';

import {
  chargeOrganisationAccount,
  activateBooking,
} from 'api/quick_travel_api/bookings';

export const createReservation = function ({ bookingId, values, clientId }) {
  return (dispatch, getState) => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    const params = {
      booking_id: bookingId,
      resource_id: values.bookingResource,
      trip_id: values.bookingTrip,
      from_route_stop_id: values.bookingFromRouteStopId,
      to_route_stop_id: values.bookingToRouteStopId.at(-1),
      first_travel_date: values.bookingDate,
      passenger_types: {
        1: 1,
      },
      quantity: 1,
    };

    createOrUpdateToast('Creating Reservations', {
      toastId: bookingId,
    });

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/bookings/${bookingId}/reservations`,
      options,
      params,
    })
      .then((result) => {
        if (result === null) {
          return;
        }
        if (getState().qt_endpoint.clients[clientId].can_charge_organisation) {
          dispatch(
            chargeOrganisationAccount({
              bookingId,
              organisationClientId: values.organisationClientId,
              serviceId: values.bookingService,
              clientId,
            })
          );
        } else {
          dispatch(
            activateBooking({
              bookingId,
              serviceId: values.bookingService,
              clientId,
            })
          );
        }
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId: clientId,
                  errorString: e.error,
                  type: 'creating reservation',
                  toastId: bookingId,
                })
              );
            });
          },
        })
      );
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { createReservation };
