import Config from 'config/config';
import Rollbar from 'rollbar';
import * as jwk from 'jsonwebtoken';

const login = function (username, password) {
  const url = new URL(`${Config.authServiceURL}/oauth/token`);

  const postData = {
    grant_type: 'password',
    username: username,
    password: password,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(postData),
  };

  return fetch(url.toString(), request)
    .then((response) => {
      if (response.error) {
        return response;
      }

      return response.json();
    })
    .then((json) => {
      if (json.error) {
        throw json;
      } else {
        return json;
      }
    })
    .then((json) => {
      const decoded = jwk.decode(json.access_token);

      Rollbar.configure({
        payload: {
          person: {
            id: decoded.sub,
            username: decoded.login,
          },
        },
      });

      return Promise.resolve(json);
    });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { login };
