import { ErrorMessage } from 'formik';
import React from 'react';
import Select from 'react-select';

export const SelectField = ({
  options,
  field,
  form,
  isMulti,
  onChange,
  className,
  labelName,
  id,
  isDisabled,
}) => {
  function selectedValue() {
    if (options) {
      if (isMulti) {
        return (
          options.filter((option) => field.value?.includes(option.value)) || []
        );
      } else {
        return options.find((option) => option.value === field.value) || '';
      }
    } else {
      return '';
    }
  }

  return (
    <div className="select-field-container">
      <label htmlFor={field.name}>{labelName}</label>
      <ErrorMessage
        className="error-feedback ml-3"
        name={field.name}
        component="span"
        data-testid={`${id}-error`}
      />
      <Select
        options={options}
        value={selectedValue()}
        onChange={(option) => {
          form.setFieldValue(
            field.name,
            isMulti ? option?.map((item) => item.value) : option.value
          );
          if (onChange) {
            onChange(option);
          }
        }}
        onBlur={field.onBlur}
        isMulti={isMulti || false}
        className={className || 'select-field'}
        closeMenuOnSelect={!isMulti}
        isDisabled={isDisabled}
      />
    </div>
  );
};
