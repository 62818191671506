import React from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { started } from 'redux/actions/qt_endpoint';
import {
  useServiceBookingsIds,
  useCanCancelBookingsOnService,
  useCanMakeBookingsOnService,
} from 'redux/selectors/services';
import {
  useOrganisationManager,
  useSelfManagedStaff,
} from 'redux/selectors/organisations';
import { serviceTimeFormat } from 'utils/time_formats';

import QuickTravelApi from 'api/quick_travel_api';

import moment from 'moment';
import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { find } from 'lodash';
import Config from 'config/config';

export function Service({ service }) {
  const { activeServiceBookingIds } = useServiceBookingsIds(service);
  const isOrganisationManager = useOrganisationManager();
  const isSelfManagedStaff = useSelfManagedStaff();
  const canCancelService = useCanCancelBookingsOnService(service.id);
  const canBookService = useCanMakeBookingsOnService(service.id);

  const segment = find(Config.segments, function (segment) {
    return (
      segment.from_stop_id === service.from_route_stop_id &&
      segment.to_stop_id.includes(service.to_route_stop_id)
    );
  });

  const loading = useSelector((state) => {
    return state.qt_endpoint.api.bookings[service.date] === started;
  });

  function renderlinkContents() {
    const departureTime =
      service.trip_stops.find(
        (stop) => stop.route_stop_id === segment.from_stop_id
      )?.time || service.departure_time;

    return (
      <div
        className={classnames({
          service: true,
          noOrganisationPassengers: activeServiceBookingIds.length === 0,
          'service-active':
            service.state_id === QuickTravelApi.Services.STATUS.ACTIVE,
          'service-stop-sell':
            service.state_id === QuickTravelApi.Services.STATUS.STOP_SELL,
          'service-cancelled':
            service.state_id === QuickTravelApi.Services.STATUS.CANCELLED,
          'service-reserved':
            service.state_id === QuickTravelApi.Services.STATUS.RESERVED,
          'non-changeable': !canCancelService && !canBookService,
        })}
      >
        <div className="service-time">
          {moment(departureTime)
            .utcOffset(departureTime)
            .format(serviceTimeFormat)}
        </div>
        {renderPassengerCount()}
      </div>
    );
  }

  function renderPassengerCount() {
    if (service.state_id !== QuickTravelApi.Services.STATUS.ACTIVE) {
      return <div className="service-pax-count">Not Available</div>;
    }

    if (isOrganisationManager) {
      if (loading) {
        return (
          <div>
            <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
          </div>
        );
      }

      return (
        <div className="service-pax-count">
          {`${activeServiceBookingIds.length} Passenger${
            activeServiceBookingIds.length === 1 ? '' : 's'
          }`}
        </div>
      );
    } else {
      return null;
    }
  }

  function renderLink() {
    // An inactive service is never interactable
    if (service.state_id !== QuickTravelApi.Services.STATUS.ACTIVE) {
      return <div>{renderlinkContents()}</div>;
    }

    if (isOrganisationManager) {
      return (
        <Link to={`/dashboard/service/${service.id}`}>
          {renderlinkContents()}
        </Link>
      );
    } else {
      if (activeServiceBookingIds.length > 0) {
        return (
          <Link to={`/dashboard/bookings/${activeServiceBookingIds[0]}`}>
            {renderlinkContents()}
          </Link>
        );
      } else if (isSelfManagedStaff && canBookService) {
        return (
          <Link to={`/dashboard/service/${service.id}/bookings/new`}>
            {renderlinkContents()}
          </Link>
        );
      } else {
        return <div>{renderlinkContents()}</div>;
      }
    }
  }

  return renderLink();
}
