import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { SelectField } from 'components/formik/select_field';
import { SingleDatePickerField } from 'components/formik/single_date_picker_field';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { filter, map, find, sortBy, isEmpty } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import Config from 'config/config';

import QuickTravelAPI from 'api/quick_travel_api';
import {
  reverseDateFormat,
  serviceTimeFormat,
  standardDateFormat,
} from 'utils/time_formats';
import moment from 'moment';
import {
  useOrganisationClientId,
  useProfile,
  useOrganisationManager,
  useSelfManagedStaff,
} from 'redux/selectors/organisations';
import { useSegments } from 'redux/selectors/segments';

import {
  useCanCancelBookingsOnService,
  useCanMakeBookingsOnService,
} from 'redux/selectors/services';

function routeOptions(collection) {
  return Object.values(collection).map((subject) => {
    return { value: subject.id, label: subject.name };
  });
}

function clientOptions(collection) {
  return Object.values(collection).map((subject) => {
    return {
      value: subject.client_id,
      label: `${subject.first_name} ${subject.last_name}`,
    };
  });
}

function renderCapacity(
  bookingService,
  associatedClientIds = [],
  selectedClientIds = []
) {
  if (bookingService) {
    const nonStaffBooked = bookingService.booked - associatedClientIds.length;

    return `${
      bookingService.capacity - (nonStaffBooked + selectedClientIds.length)
    } seats available when applied`;
  }
}

function dateFromService(service) {
  return service ? new moment(service.date, reverseDateFormat) : null;
}

export function BookingCreator({ match, history, handleSubmit, closeModal }) {
  const dispatch = useDispatch();
  const [bookingResource, setBookingResource] = useState();
  const [bookingTrip, setBookingTrip] = useState();
  const [bookingFromRouteStopId, setBookingFromRouteStopId] = useState();
  const [bookingToRouteStopId, setBookingToRouteStopId] = useState();
  const [bookingSegment, setBookingSegment] = useState();
  const [serviceIDs, setServiceIDs] = useState([]);

  const routes = useSelector((state) => state.qt_endpoint.routes);
  const clients = useSelector((state) => state.qt_endpoint.clients);
  const initialServiceId = match.params?.service_id;
  const organisationClientId = useOrganisationClientId();
  const isOrganisationManager = useOrganisationManager();
  const isSelfManagedStaff = useSelfManagedStaff();
  const profile = useProfile();

  const services = useSelector((state) => {
    return filter(state.qt_endpoint.services, (service) => {
      if (serviceIDs && bookingSegment) {
        return (
          serviceIDs.includes(service.id) &&
          service.from_route_stop_id === bookingSegment.from_stop_id &&
          bookingSegment.to_stop_id.includes(service.to_route_stop_id) &&
          service.state_id === QuickTravelAPI.Services.STATUS.ACTIVE
        );
      } else {
        return [];
      }
    });
  });

  const initialService = useSelector((state) => {
    if (initialServiceId) {
      return find(state.qt_endpoint.services, (service) => {
        return parseInt(service.id) === parseInt(initialServiceId);
      });
    }
    return undefined;
  });
  const [bookingService, setBookingService] = useState(initialService);
  const [bookingDate, setBookingDate] = useState();

  const canCancelBookingService = useCanCancelBookingsOnService(
    bookingService?.id
  );
  const canMakeBookingsService = useCanMakeBookingsOnService(
    bookingService?.id
  );

  const associatedClientIds = useSelector((state) => {
    if (profile && !isOrganisationManager) {
      return [profile.client_id];
    } else {
      if (bookingService?.booking_ids && state.qt_endpoint.bookings) {
        return filter(state.qt_endpoint.bookings, (booking) => {
          return (
            bookingService.booking_ids.includes(booking.id) &&
            booking.state === 'active'
          );
        }).map((booking) => {
          return booking.client_id;
        });
      }
      return [];
    }
  });

  useEffect(() => {
    if (initialService) {
      const segment = find(Config.segments, function (segment) {
        return (
          segment.from_stop_id === initialService?.from_route_stop_id &&
          segment.to_stop_id.includes(initialService?.to_route_stop_id)
        );
      });
      setBookingSegment(segment);
      setBookingFromRouteStopId(segment?.from_stop_id);
      setBookingToRouteStopId(segment?.to_stop_id);
      setBookingDate(dateFromService(initialService));
      setBookingResource(initialService?.resource_id);
      setBookingTrip(initialService?.trip_id);
      setBookingService(initialService);
    }
  }, [initialService]);

  function serviceOptions(services) {
    if (bookingSegment === undefined) {
      return [];
    }
    const sortedServices = sortBy(services, (service) => {
      const bookingRoute = bookingSegment.route_id;
      const route = routes[bookingRoute];
      const trip = route.trips[service.trip_id];

      return trip ? trip.departure_time : service.selection_name;
    });

    return map(sortedServices, (service) => {
      const bookingRoute = bookingSegment.route_id;
      const route = routes[bookingRoute];
      const trip = route.trips[service.trip_id];

      const departureTime =
        service.trip_stops.find(
          (stop) => stop.route_stop_id === bookingFromRouteStopId
        )?.time || trip.departure_time;

      const labelPrefix = trip
        ? moment(departureTime).utc().format(serviceTimeFormat)
        : service.selection_name;

      return {
        value: service.id,
        label: `${labelPrefix} - ${
          service.capacity - service.booked
        } seats available`,
      };
    });
  }

  useEffect(() => {
    const bookingRoute = bookingSegment?.route_id;
    const stateContainsServicesForRouteAndDate =
      routes &&
      bookingDate &&
      routes[bookingRoute] &&
      routes[bookingRoute]['dates'] &&
      routes[bookingRoute]['dates'][bookingDate.format(reverseDateFormat)];
    if (bookingDate && bookingRoute && !stateContainsServicesForRouteAndDate) {
      dispatch(
        QuickTravelAPI.Services.index({
          date: bookingDate,
          routeId: bookingRoute,
        })
      );
    }
  }, [bookingDate, dispatch, bookingSegment, routes]);

  const segments = useSegments(Config.segments);

  useEffect(() => {
    if (routes && bookingSegment && bookingSegment.route_id && bookingDate) {
      setServiceIDs(
        routes[bookingSegment.route_id].dates[
          bookingDate.format(reverseDateFormat)
        ]
      );
    }
  }, [routes, bookingSegment, bookingDate]);

  const renderPassengerSelector = (formClients) => {
    if (isOrganisationManager) {
      return (
        <div className="form-group">
          <Field
            name="bookingClients"
            options={clientOptions(clients)}
            component={SelectField}
            isMulti={true}
            id="booking-client-select"
            labelName={`Passengers (${formClients?.length || 0})`}
            isDisabled={false}
          />
          <div className="pax-capacity-info">
            {renderCapacity(bookingService, associatedClientIds, formClients)}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderBookButton = ({ isSubmitting }) => {
    if (isOrganisationManager || isSelfManagedStaff) {
      if (isEmpty(bookingService)) {
        return null;
      } else {
        if (!canCancelBookingService && !canMakeBookingsService) {
          return (
            <button
              className="btn btn-secondary btn-lg w-100 disabled btn-disabled"
              type="submit"
              disabled={true}
            >
              Bookings cannot be added to this service
            </button>
          );
        } else {
          return (
            <button
              className="btn btn-primary btn-lg w-100"
              data-testid="booking-submit"
              type="submit"
              disabled={isSubmitting}
            >
              Book
            </button>
          );
        }
      }
    }
    return (
      <div className="alert alert-warning">
        You do not have the appropriate permissions to self-manage bookings.
        Please contact your manager or the SeaLink ticketing office for
        bookings.
      </div>
    );
  };

  const renderHeadingText = () => {
    if (isOrganisationManager) {
      return <h2 className="mb-3">Add or remove staff from service</h2>;
    }
    return <h2 className="mb-3">Create Booking For Service</h2>;
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          bookingRoute: bookingSegment?.id,
          bookingDate: bookingDate?.format(standardDateFormat),
          bookingService: bookingService?.id,
          bookingClients: associatedClientIds,
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            handleSubmit({
              values: {
                ...values,
                bookingResource,
                bookingTrip,
                bookingFromRouteStopId,
                bookingToRouteStopId,
                organisationClientId,
                isOrganisationManager,
              },
            })
          );
          history.push('/dashboard');
        }}
        validationSchema={BookingSchema}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form data-testid="booking-form">
            <div className="modal-header">
              <div className="text-right">
                <FontAwesomeIcon
                  onClick={closeModal}
                  className="modal-close-btn"
                  icon={faTimes}
                  fixedWidth
                />
              </div>
              {renderHeadingText()}
            </div>
            <div className="form-group">
              <Field
                name="bookingRoute"
                options={routeOptions(segments)}
                component={SelectField}
                isMulti={false}
                onChange={(e) => {
                  setBookingSegment(
                    find(Config.segments, function (segment) {
                      return segment.id === e.value;
                    })
                  );
                  setBookingService(undefined);
                }}
                labelName="Route"
                isDisabled={Boolean(initialServiceId)}
                id="booking-route-select"
              />
            </div>

            <div className="form-group">
              <Field
                name="bookingDate"
                component={SingleDatePickerField}
                displayFormat={standardDateFormat}
                labelName="Date"
                id="booking-date-select"
                onDateChange={(e) => {
                  setBookingDate(e);
                  setBookingService(undefined);
                }}
                disabled={Boolean(initialServiceId)}
              />
            </div>

            <div className="form-group">
              <Field
                name="bookingService"
                options={serviceOptions(services)}
                component={SelectField}
                isMulti={false}
                onChange={(e) => {
                  var service = services.find(
                    (service) => service.id === e.value
                  );
                  setBookingService(service);
                  setBookingResource(service.resource_id);
                  setBookingTrip(service.trip_id);
                  setBookingFromRouteStopId(bookingSegment.from_stop_id);
                  setBookingToRouteStopId(bookingSegment.to_stop_id);
                }}
                labelName="Time"
                id="booking-service-select"
                isDisabled={Boolean(initialServiceId)}
              />
            </div>
            {renderPassengerSelector(values.bookingClients)}
            <div className="mt-4">{renderBookButton({ isSubmitting })}</div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const BookingSchema = Yup.object().shape({
  bookingRoute: Yup.string().required('Required'),
  bookingDate: Yup.string().required('Required'),
  bookingService: Yup.string().required('Required'),
  bookingClients: Yup.array().of(Yup.string()),
});
