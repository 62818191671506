import Config from 'config/config';

const barcode = function ({ data, accessToken }) {
  const url = new URL(`${Config.barcodeServiceURL}`);

  url.searchParams.append('data', data);
  url.searchParams.append('binary', false);
  url.searchParams.append('filetype', 'PNG');
  url.searchParams.append('scale', 1);
  // This determines what format the barcode is generated in
  // See https://zint.org.uk/manual/chapter/4 for a full list of codes
  // (use the value in the "Numeric Value" column)
  url.searchParams.append('barcode', 20);

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json.barcode;
    })
    .catch((error) => {
      // Unable to generate check barcode
    });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { barcode };
