import { createReducer } from '@reduxjs/toolkit';

import { login, logout, setProfile } from 'redux/actions/login';

import localStore from 'utils/local_store';

const defaultInitialState = {
  authenticatedUser: undefined,
  profile: undefined,
};

export const initialState = () => {
  const user = localStore.get('user');

  const oktaUser = localStore.get('okta-token-storage');

  if (user != null) {
    return { ...defaultInitialState, authenticatedUser: user };
  }

  if (oktaUser != null) {
    const user = {
      access_token: oktaUser.accessToken.accessToken,
      created_at: oktaUser.accessToken.claims.auth_time,
      expires_in: oktaUser.accessToken.expiresAt,
      scope: oktaUser.accessToken.scopes,
      token_type: oktaUser.accessToken.tokenType,
    };
    localStore.set('user', user);
    return {
      ...defaultInitialState,
      authenticatedUser: user,
    };
  }

  return defaultInitialState;
};

const LoginReducer = createReducer(initialState(), {
  [login]: (state, action) => {
    localStore.set('user', action.payload);

    return {
      ...defaultInitialState,
      authenticatedUser: action.payload,
    };
  },
  [logout]: (state, action) => {
    localStore.unset('user');
    localStore.unset('okta-cache-storage');
    localStore.unset('okta-token-storage');
    localStore.unset('okta-shared-transaction-storage');
    localStore.unset('okta-original-uri-storage');
    return defaultInitialState;
  },
  [setProfile]: (state, action) => {
    state.profile = action.payload;
  },
});

export default LoginReducer;
