import React, { useState, useEffect, useCallback } from 'react';
import { find, mapValues, every, isEmpty } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';

import QuickTravelAPI from 'api/quick_travel_api';
import Config from 'config/config';
import { isBookingStub, bookingBelongsTo } from 'helpers/bookings_helper';

import { useProfile } from 'redux/selectors/organisations';

import {
  reportAndDisplayError,
  createOrUpdateToast,
} from 'api/quick_travel_api/utils';
import braintree from 'braintree-web';

import currency from 'currency.js';
import { toast } from 'react-toastify';

import { renderHeading } from 'components/modals/heading';

export function Pay({ match, closeModal, history }) {
  const dispatch = useDispatch();

  // Fetch booking information
  const bookingId = match.params.booking_id;
  const booking = useSelector((state) => {
    const obj = state.qt_endpoint.bookings[bookingId];

    if (isBookingStub(obj)) {
      return undefined;
    }

    return obj;
  });

  useEffect(() => {
    if (isBookingStub(booking)) {
      // This is a booking stub, fetch the whole booking
      dispatch(QuickTravelAPI.Bookings.show({ bookingId, closeModal }));
    }
  }, [booking, dispatch, bookingId, closeModal]);

  const profile = useProfile();

  const [bookingPaid, setBookingPaid] = useState(false);

  useEffect(() => {
    setBookingPaid(booking?.balance_in_cents === 0);
  }, [booking]);

  useEffect(() => {
    if (profile) {
      dispatch(QuickTravelAPI.Bookings.getBraintreeClientToken());
    }
  }, [profile, dispatch]);

  const braintreeClientToken = useSelector((state) => {
    return state.qt_endpoint.braintreeClientToken;
  });

  const [braintreeHostedFields, setBraintreeHostedFields] = useState();

  const initialiseBraintree = useCallback(() => {
    if (!braintreeClientToken || bookingPaid) {
      return null;
    }

    braintree.client.create(
      { authorization: braintreeClientToken },
      (clientErr, clientInstance) => {
        if (clientErr) {
          return dispatch(reportAndDisplayError(clientErr));
        }
        braintree.hostedFields.create(
          {
            client: clientInstance,
            fields: {
              cardholderName: {
                container: '#braintree-cardholder-name-input',
                supportedCardBrands: {
                  visa: true,
                  mastercard: true,
                  'american-express': true,
                  'diners-club': false,
                  discover: false,
                  jcb: false,
                  'union-pay': false,
                  mestro: false,
                  elo: false,
                  mir: false,
                  hiper: false,
                  hipercard: false,
                },
              },
              number: {
                container: '#braintree-card-number-input',
                placeholder: 'XXXX XXXX XXXX XXXX',
              },
              cvv: {
                container: '#braintree-cvv-input',
                placeholder: 'XXX',
              },
              expirationDate: {
                container: '#braintree-expiry-date-input',
                placeholder: 'MM/YY',
              },
            },
            styles: {
              '.valid': {
                color: '#28a745',
              },
              '.invalid': {
                color: '#dc3545',
              },
              input: {
                'font-size': '1rem',
              },
            },
          },
          (hostedFieldsErr, hostedFieldsInstance) => {
            if (hostedFieldsErr) {
              return dispatch(reportAndDisplayError(hostedFieldsErr));
            }

            hostedFieldsInstance.on('validityChange', (event) => {
              document.getElementById('payment-submit').disabled = !every(
                mapValues(event.fields, 'isValid')
              );
            });
            setBraintreeHostedFields(hostedFieldsInstance);
          }
        );
      }
    );
  }, [bookingPaid, braintreeClientToken, dispatch]);

  useEffect(() => {
    initialiseBraintree();
  }, [initialiseBraintree]);

  function handleBraintreeFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    braintreeHostedFields.tokenize(function (err, payload) {
      if (!isEmpty(err)) {
        dispatch(reportAndDisplayError(err));
        return;
      }

      const data = {
        payment_data: {
          payment_method_nonce: payload.nonce,
        },
      };

      // TODO: we will want to remove this from config and probably
      // pull the payment types directly from QT.
      // We have different payment type ids for each credit card brand
      // on each tenant so we should build this rather than have it
      // stored staticly in config
      const paymentTypeId = find(Config.creditCards, (card) => {
        return payload.details.cardType === card.name;
      }).paymentTypeId;

      dispatch(
        QuickTravelAPI.Bookings.braintreePayBooking({
          data,
          bookingId: booking.id,
          paymentTypeId,
        })
      );
      history.push(`/dashboard/bookings/${booking.id}`);
    });
  }

  function bookingPayable() {
    if (booking.state !== 'active') {
      createOrUpdateToast(
        `Booking ${booking.reference} is not in a payable state.`,
        {
          toastId: booking.reference,
          type: toast.TYPE.ERROR,
        }
      );
      history.push('/dashboard');
      return false;
    }
    if (bookingBelongsTo({ clientId: profile?.client_id, booking }) === false) {
      createOrUpdateToast(
        'You do not have permission to perform this action.',
        {
          toastId: booking.reference,
          type: toast.TYPE.ERROR,
        }
      );
      history.push('/dashboard');
      return false;
    }
    if (bookingPaid) {
      createOrUpdateToast(
        `Booking ${booking.reference} has already been paid.`,
        {
          toastId: booking.reference,
        }
      );
      history.push(`/dashboard/bookings/${booking.id}`);
      return false;
    }
    return true;
  }

  function renderAmount() {
    const surcharge = Config.braintreeSurcharge * booking.balance_in_cents;

    const amountFormatted = currency(booking.balance_in_cents, {
      fromCents: true,
    }).format();
    const surchargeFormatted = currency(surcharge, {
      fromCents: true,
    }).format();
    const amountWithSurchargeFormatted = currency(
      booking.balance_in_cents + surcharge,
      {
        fromCents: true,
      }
    ).format();

    return (
      <div>
        <h3>
          {amountFormatted} + {surchargeFormatted} surcharge.
        </h3>
        <h2>{amountWithSurchargeFormatted} total outstanding.</h2>
      </div>
    );
  }

  if (!booking) {
    return <h2>Loading booking data...</h2>;
  } else if (!bookingPayable()) {
    return null;
  }

  return (
    <React.Fragment>
      {renderHeading({ closeModal, history })}

      <form id="braintree-form" onSubmit={handleBraintreeFormSubmit}>
        <div className="mb-4">
          <h1>Paying booking {booking.reference}</h1>
          {renderAmount()}
        </div>
        <div className="form-group" id="braintree-cardholder-name">
          <label htmlFor="braintree-cardholder-name">Name On Card</label>
          <div
            name="braintreeCardholderName"
            id="braintree-cardholder-name-input"
            className="hosted-field form-control"
          />
        </div>

        <div className="form-group" id="braintree-card-number">
          <label htmlFor="braintree-card-number">Card Number</label>

          <div
            name="braintreeCardNumber"
            id="braintree-card-number-input"
            className="hosted-field form-control"
          />
        </div>

        <div className="form-group" id="braintree-expiry-date">
          <label htmlFor="braintree-expiry-date">Expiry Date</label>

          <div
            name="braintreeExpiryDate"
            id="braintree-expiry-date-input"
            className="hosted-field form-control"
          />
        </div>

        <div className="form-group" id="braintree-cvv">
          <label htmlFor="braintree-cvv">CVV</label>

          <div
            name="braintreeCVV"
            id="braintree-cvv-input"
            className="hosted-field form-control"
          />
        </div>

        <button
          id="payment-submit"
          className="btn btn-primary"
          type="submit"
          disabled={true}
        >
          Pay with <span id="card-brand">Card</span>
        </button>
      </form>
    </React.Fragment>
  );
}

export default Pay;
