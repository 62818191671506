import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const issuer = process.env.REACT_APP_OKTA_ISSUER;
const clientId = process.env.REACT_APP_OKTA_CLIENTID;
const redirect = process.env.REACT_APP_OKTA_APP_BASE_URL + '/callback';

export const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: redirect,
});

export const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.replace(
    toRelativeUrl(originalUri || '/', window.location.origin)
  );
};
