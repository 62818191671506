import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

import QuickTravelAPI from 'api/quick_travel_api';

import { logout } from 'redux/actions/login';
import {
  useOrganisationManager,
  useOrganisationId,
  useAuthenticatedUser,
  useSelfManagedStaff,
} from 'redux/selectors/organisations';

import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { RoutesTable } from 'components/routes_table';
import { Link } from 'react-router-dom';
import { SingleDatePicker } from 'react-dates';

import useWindowSize, {
  IPAD_WIDTH,
  FULL_WEEK_DAYS,
  SINGLE_DAYS,
} from 'utils/windowSize';

import { standardDateFormat } from 'utils/time_formats';

// Stubbed component
export function ActionBar({ isSelfManagedStaff, isOrganisationManager }) {
  if (isSelfManagedStaff || isOrganisationManager) {
    return (
      <div className="action-bar mb-5">
        <Link
          to="/dashboard/bookings/new"
          className="btn btn-primary btn-create-booking d-block d-md-inline-block my-5 my-md-0"
        >
          <FontAwesomeIcon icon={faPlusCircle} fixedWidth /> Create New Booking
        </Link>
      </div>
    );
  }
  return null;
}

export function App({
  isOrganisationManager,
  isSelfManagedStaff,
  logout,
  routeIds,
  date,
}) {
  const [days, setDays] = useState(FULL_WEEK_DAYS);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= IPAD_WIDTH) {
      setDays(SINGLE_DAYS);
    } else {
      setDays(FULL_WEEK_DAYS);
    }
  }, [width]);

  function travelTitle() {
    if (isOrganisationManager) {
      return 'Upcoming Travel';
    } else {
      return 'My Upcoming Travel';
    }
  }

  const [dateFilterFocused, setDateFilterFocused] = useState(false);
  const [filterDate, setFilterDate] = useState();
  const permissionProps = { isSelfManagedStaff, isOrganisationManager };

  function routeTableDate() {
    return filterDate ? filterDate.format() : date;
  }

  function renderPassengerFilter() {
    if (isOrganisationManager) {
      return (
        <Link
          to={{
            pathname: '/dashboard/future_bookings',
            query: { myBookingList: false },
          }}
          className="btn btn-outline-primary text-nowrap ml-3"
        >
          Filter by passengers
        </Link>
      );
    }
  }

  function renderMyBookingList() {
    return (
      <Link
        to={{
          pathname: '/dashboard/future_bookings',
          query: { myBookingList: true },
        }}
        className="btn btn-outline-primary text-nowrap ml-3"
      >
        My booking list
      </Link>
    );
  }

  return (
    <>
      <div className="container">
        <Header logout={logout} />
        <div className="main">
          <ActionBar {...permissionProps} />

          <div className="d-flex align-items-md-center justify-content-md-between flex-column flex-md-row my-4">
            <h1 className="dashboard-title mb-3 mb-md-0">{travelTitle()}</h1>

            <div className="filters-wrapper">
              <form
                className="route-filters d-flex align-items-center"
                action=""
              >
                {renderPassengerFilter()}
                {renderMyBookingList()}
                <div className="w-auto ml-3 float-right">
                  <SingleDatePicker
                    date={filterDate}
                    numberOfMonths={1}
                    onDateChange={(option) => setFilterDate(option)}
                    placeholder="Search by date"
                    onFocusChange={(e) => setDateFilterFocused(e.focused)}
                    focused={dateFilterFocused}
                    hideKeyboardShortcutsPanel={true}
                    displayFormat={standardDateFormat}
                    showClearDate={true}
                    reopenPickerOnClearDate={false}
                    isOutsideRange={() => false}
                  />
                </div>
              </form>
            </div>
          </div>
          <RoutesTable {...{ routeIds, days, date: routeTableDate() }} />
        </div>
      </div>

      <Footer />
    </>
  );
}

export const Dashboard = (props) => {
  const dispatch = useDispatch();

  const user = useAuthenticatedUser();
  const organisationId = useOrganisationId();
  const isOrganisationManager = useOrganisationManager();
  const isSelfManagedStaff = useSelfManagedStaff();

  useEffect(() => {
    dispatch(QuickTravelAPI.Profile.me());
  }, [dispatch, user]);

  useEffect(() => {
    if (organisationId) {
      dispatch(QuickTravelAPI.Organisations.show({ organisationId }));
    }
  }, [organisationId, dispatch, user]);

  return (
    <App
      {...{
        isOrganisationManager,
        isSelfManagedStaff,
        logout: () => {
          dispatch(logout());
        },
        ...props,
      }}
    />
  );
};

export default Dashboard;
