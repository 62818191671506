import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

export function LoginForm({ login, onLogin }) {
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [loginText, setLoginText] = useState('Login');
  const [error, setError] = useState();

  const handleOnKeyPress = (event) => {
    const isCapsLockOn =
      event.getModifierState && event.getModifierState('CapsLock');

    return setCapsLockOn(isCapsLockOn);
  };

  const renderCapslockWarning = () => {
    if (capsLockOn) {
      return (
        <div className="alert alert-warning">
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
          You have caps lock on
        </div>
      );
    }

    return null;
  };

  const renderError = () => {
    if (error) {
      return (
        <div className="alert alert-danger">
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
          {error}
        </div>
      );
    }

    return null;
  };

  return (
    <Formik
      initialValues={{ login: '', password: '' }}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        setLoginText('Logging In...');
        setError(undefined);

        login(values.login, values.password)
          .then((response) => {
            onLogin(response);
          })
          .catch((err) => {
            if (err.error === 'invalid_grant') {
              setError('Invalid Username / Password Combination');
            } else {
              console.log('error', err);
              setError(err.error_description);
            }
          })
          .finally(() => {
            setLoginText('Log In');
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="login">Username</label>
            <ErrorMessage
              className="error-feedback ml-3"
              name="login"
              component="span"
            />
            <Field
              className="form-control"
              name="login"
              onKeyPress={handleOnKeyPress}
              placeholder="Your username"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <ErrorMessage
              className="error-feedback ml-3"
              name="password"
              component="span"
            />
            <Field
              className="form-control"
              type="password"
              name="password"
              onKeyPress={handleOnKeyPress}
              placeholder="Your password"
            />
          </div>

          {renderCapslockWarning()}

          {renderError()}

          <button
            className="btn btn-primary btn-lg mt-4 w-100"
            type="submit"
            disabled={isSubmitting}
          >
            {loginText}
          </button>
        </Form>
      )}
    </Formik>
  );
}

const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  login: Yup.string().required('Required'),
});

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  onLogin: undefined,
  login: undefined,
};
