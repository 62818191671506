import Config from 'config/config';

import { isEmpty } from 'lodash';

import {
  setServices,
  fetchServicesStarted,
  fetchServicesFinished,
  fetchServicesError,
} from 'redux/actions/qt_endpoint';
import { authorizedFetch, reportAndDisplayError } from './utils';

import { normalize, servicesResult } from './schemas';

import moment from 'moment';
import { reverseDateFormat } from 'utils/time_formats';

const STATUS = {
  ACTIVE: 0,
  STOP_SELL: 1,
  CANCELLED: 2,
  RESERVED: 3,
};

const REASON = {
  GENERAL: 0,
  SCHEDULE: 1,
  MECHANICAL: 2,
  WEATHER: 3,
};

const index = function ({ routeId, date, forceReload = false }) {
  const dateString = date.format(reverseDateFormat);

  return (dispatch, getState) => {
    const serviceIdArray = getState().qt_endpoint?.routes[routeId]?.dates?.[
      dateString
    ];

    if (serviceIdArray && !forceReload) {
      // It's already loaded
      return Promise.resolve();
    }

    const options = {
      method: 'GET',
    };

    const url = new URL(
      `${Config.quickTravelURL}/api/routes/${routeId}/services`
    );

    url.searchParams.append('date', dateString);
    url.searchParams.append('include_booking_ids', 'true');

    dispatch(fetchServicesStarted({ date: dateString, routeId }));

    return authorizedFetch(dispatch, getState, {
      url,
      options,
    })
      .then((jsonData) => {
        if (isEmpty(jsonData)) {
          return { entities: { services: {} } };
        }

        return normalize(
          { id: 'dummyObjectForNormalization', services: jsonData },
          servicesResult
        );
      })
      .then((normalizedData) => {
        const services = normalizedData.entities.services;
        dispatch(fetchServicesFinished({ date: dateString, routeId }));
        dispatch(setServices({ routeId, date: dateString, services }));
      })
      .catch((error) => {
        dispatch(fetchServicesError({ date: dateString, routeId }));
        dispatch(reportAndDisplayError(error));
      });
  };
};

const getServicesForBooking = function (booking) {
  const routeId = booking.reservations_attributes[0].route_id;
  const bookingTravelDate = booking.first_travel_date._value;
  return (dispatch, getState) => {
    if (isEmpty(getState().qt_endpoint.api[routeId]?.[bookingTravelDate])) {
      dispatch(
        index({
          date: new moment(bookingTravelDate),
          routeId: routeId,
        })
      );
    }
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { index, getServicesForBooking, STATUS, REASON };
