// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { isEmpty } from 'lodash';

export function PrivateRoute({ children, ...rest }) {
  const { authenticatedUser } = useSelector((state) => state.login);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isEmpty(authenticatedUser) ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : (
          children
        );
      }}
    />
  );
}
