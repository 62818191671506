import React, { useEffect } from 'react';

import QuickTravelAPI from 'api/quick_travel_api';

import { Service } from 'components/service';

import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { shortDateFormat, reverseDateFormat } from 'utils/time_formats';
import { sortBy } from 'lodash';

import {
  useAuthenticatedUser,
  useGetRoutesApiRight,
} from 'redux/selectors/organisations';

export function Day({ date, segment }) {
  const dispatch = useDispatch();

  const user = useAuthenticatedUser();
  const hasGetRoutesApiRight = useGetRoutesApiRight();

  useEffect(() => {
    if (!hasGetRoutesApiRight) {
      return;
    }
    dispatch(
      QuickTravelAPI.Services.index({ date, routeId: segment.route_id })
    );
  }, [date, dispatch, segment, user, hasGetRoutesApiRight]);

  const route = useSelector((state) => {
    if (state.qt_endpoint.routes[segment.route_id] === undefined) {
      return {
        name: '',
      };
    } else {
      return state.qt_endpoint.routes[segment.route_id];
    }
  });

  const serviceIds = useSelector((state) => {
    if (!route.dates) {
      return undefined;
    }
    return route.dates[date.format(reverseDateFormat)];
  });

  const services = useSelector((state) => {
    if (!route || route.trips === undefined) {
      return [];
    }

    const shortList = (serviceIds || [])
      .map((serviceId) => state.qt_endpoint.services[serviceId])
      .filter((service) => {
        return (
          service.from_route_stop_id === segment.from_stop_id &&
          segment.to_stop_id.includes(service.to_route_stop_id)
        );
      })
      .filter((service) => {
        return (
          QuickTravelAPI.Services.STATUS.ACTIVE === service.state_id ||
          QuickTravelAPI.Services.STATUS.STOP_SELL === service.state_id
        );
      });

    return sortBy(
      shortList,
      (service) =>
        service.trip_stops.find(
          (stop) => stop.route_stop_id === segment.from_stop_id
        )?.time
    );
  });

  const renderServices = () => {
    if (services.length === 0) {
      if (serviceIds) {
        return <div className="service service-none">No Services</div>;
      } else {
        return <div className="service service-none">Loading&hellip;</div>;
      }
    }

    return services.map((service) => {
      return <Service key={`Service--${service.id}`} service={service} />;
    });
  };

  return (
    <div className="day">
      <div className="date">{moment(date).format(shortDateFormat)}</div>
      {renderServices()}
    </div>
  );
}
