import React, { useEffect } from 'react';
import moment from 'moment';
import { useSegmentName } from 'redux/selectors/segments';
import { useBookingServices } from 'redux/selectors/services';
import {
  tripDetailsFromBooking,
  bookingBelongsTo,
  isBookingStub,
} from 'helpers/bookings_helper';
import { ModalLink } from 'react-router-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { useProfile } from 'redux/selectors/organisations';
import { serviceTimeFormat } from 'utils/time_formats';
import QuickTravelAPI from 'api/quick_travel_api';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

export function BookingCard({ booking }) {
  const dispatch = useDispatch();
  const tripDetails = tripDetailsFromBooking(booking);
  const profile = useProfile();
  const services = useBookingServices(booking.id);

  useEffect(() => {
    if (isEmpty(services) && !isBookingStub(booking)) {
      return dispatch(QuickTravelAPI.Services.getServicesForBooking(booking));
    }
  }, [booking, dispatch, services]);

  const renderPaymentStatus = function () {
    if (parseInt(booking.balance_in_cents) !== 0) {
      if (bookingBelongsTo({ clientId: profile?.client_id, booking })) {
        return (
          <ModalLink
            linkClassName="card-link ml-2"
            path={`/dashboard/pay_booking/${booking.id}`}
          >
            <FontAwesomeIcon icon={faDollarSign} fixedWidth />
            Pay Booking
          </ModalLink>
        );
      } else {
        return <span className="p-2">Booking Unpaid</span>;
      }
    }
  };

  function departureTime() {
    return services.sort((a, b) =>
      moment(a.departure_time).isBefore(moment(b.departure_time)) ? -1 : 1
    )[0]?.departure_time;
  }

  return (
    <div className="card">
      <div className="card-body">
        <p className="mb-2">
          <strong>{useSegmentName(tripDetails?.segment)}</strong>
        </p>
        <p className="mb-2">
          {tripDetails?.departureDate} |{' '}
          {!isEmpty(services)
            ? moment(departureTime())
                ?.utcOffset(departureTime())
                ?.format(serviceTimeFormat)
            : ''}
          <br />
          {booking?.reference}
        </p>
        <ModalLink
          linkClassName="card-link"
          path={`/dashboard/bookings/${booking.id}`}
        >
          <FontAwesomeIcon icon={faInfoCircle} fixedWidth /> Booking Info
        </ModalLink>
        {renderPaymentStatus()}
      </div>
    </div>
  );
}
