import Config from 'config/config';
import { isEmpty, isFunction, filter } from 'lodash';
import { initRollbar } from 'utils/rollbar';

import {
  fetchBookingsStarted,
  fetchBookingsFinished,
  fetchBookingsError,
  setEntities,
  addBookingToService,
  started,
  setBookingCancelled,
} from 'redux/actions/qt_endpoint';
import {
  authorizedFetch,
  reportAndDisplayError,
  generateClientErrorMessage,
  handleErrorStatus,
} from './utils';
import { hasManagerRight } from 'redux/selectors/organisations';

import { normalize, booking } from './schemas';
import { createReservation } from 'api/quick_travel_api/reservations';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import { reverseDateFormat } from 'utils/time_formats';

import { toast } from 'react-toastify';
import { createOrUpdateToast } from 'api/quick_travel_api/utils';

import BarcodeService from 'api/barcode_service';
import { serviceWithinWindow } from 'helpers/services_helper';

export const perPageLimit = 100;
export const pageSafetyLimit = 200;
export const maxPageError = 'Max Page Number Reached';

var Rollbar = initRollbar();

const index = function ({ date, page = 1, days = 14 }) {
  const fromDateString = moment(date).format(reverseDateFormat);
  return (dispatch, getState) => {
    // Safety net in the case QT has way too many pages, or inifite loop
    if (page >= pageSafetyLimit) {
      dispatch(fetchBookingsFinished({ date: fromDateString, days }));
      dispatch(reportAndDisplayError(maxPageError));
      return;
    }

    const options = {
      method: 'GET',
    };

    const url = new URL(`${Config.quickTravelURL}/api/bookings`);

    // Determine if you're manager or a pleb
    const profile = getState().login.profile;
    if (!profile) {
      return;
    }

    if (page === 1) {
      const fetchStatus = getState().qt_endpoint.api.bookings[fromDateString];
      if (fetchStatus === started && page === 1) {
        // Abort if we're already fetching this data
        return;
      } else {
        dispatch(fetchBookingsStarted({ date: fromDateString, days }));
      }
    }

    if (hasManagerRight(profile)) {
      // If you're a a manger
      url.searchParams.append('organisation_id', profile.organisation_ids[0]);
    } else if (profile.client_id) {
      // If you're an ordinary staff member
      url.searchParams.append('client_id', profile.client_id);
    } else {
      return;
    }

    url.searchParams.append('limit', perPageLimit);
    url.searchParams.append('page', page);
    url.searchParams.append('full_response', false);
    url.searchParams.append('first_travel_date[from]', fromDateString);
    url.searchParams.append(
      'first_travel_date[to]',
      moment(date).add(days, 'days').format(reverseDateFormat)
    );

    return authorizedFetch(dispatch, getState, {
      url,
      options,
    })
      .then((jsonData) => {
        if (isEmpty(jsonData)) {
          dispatch(fetchBookingsFinished({ date: fromDateString, days }));
          return { entities: { bookings: {} } };
        }

        if (jsonData.length === perPageLimit) {
          // Fetch the next page
          dispatch(index({ date, page: page + 1 }));
        } else {
          // Finished Fetching
          dispatch(fetchBookingsFinished({ date: fromDateString, days }));
        }

        return normalize(jsonData, [booking]);
      })
      .then((normalizedData) => {
        const bookings = normalizedData.entities.bookings;

        dispatch(setEntities({ entities: { bookings } }));
      })
      .catch((error) => {
        dispatch(fetchBookingsError({ date: fromDateString, days }));
        dispatch(reportAndDisplayError(error));
      });
  };
};

const show = ({ bookingId, closeModal, history }) => {
  return (dispatch, getState) => {
    const options = {
      method: 'GET',
    };

    const url = new URL(`${Config.quickTravelURL}/api/bookings/${bookingId}`);

    return authorizedFetch(dispatch, getState, {
      url,
      options,
    })
      .then((jsonData) => {
        if (isEmpty(jsonData)) {
          return { entities: { bookings: {} } };
        }

        return normalize(jsonData, booking);
      })
      .then((normalizedData) => {
        const bookings = normalizedData.entities.bookings;

        dispatch(setEntities({ entities: { bookings } }));
      })
      .catch(
        handleErrorStatus({
          status: 403,
          handler: (error) => {
            if (closeModal && isFunction(closeModal)) {
              closeModal();
            } else {
              history.push('/');
            }
          },
        })
      )
      .catch((error) => dispatch(reportAndDisplayError(error)));
  };
};

export const createBookingAndReservation = function ({ values, clientId }) {
  return (dispatch, getState) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Origin-Key': Config.accessKey,
      },
    };

    if (!withinBookingWindow(values.bookingService, getState())) {
      dispatch(
        reportAndDisplayError(
          `Unable to create booking. Booking window has closed (${Config.bookingCutoffMinutes} minutes)`,
          {
            toastId: values.bookingService,
          }
        )
      );

      return Promise.resolve();
    }

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/bookings`,
      options,
      params: {
        booking: { client_id: clientId, skip_active_booking_check: true },
      },
    })
      .then((result) => {
        if (isEmpty(result)) {
          return;
        }

        createOrUpdateToast(`Booking Created: ${result.reference}`, {
          toastId: result.id,
          type: toast.TYPE.INFO,
        });

        const bookingResult = { ...result, client_id: result.client?.id };

        dispatch(
          setEntities({
            entities: {
              bookings: {
                [result.id]: bookingResult,
              },
            },
          })
        );

        dispatch(
          updateBookingAttributes({ bookingId: result.id, clientId, values })
        );
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId,
                  errorString: e.error,
                  type: 'creating booking',
                  toastId: `client${clientId}`,
                })
              );
            });
          },
        })
      );
  };
};

export const updateBookingAttributes = function ({
  bookingId,
  values,
  clientId,
}) {
  return (dispatch, getState) => {
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };
    const booking = getState().qt_endpoint.bookings[bookingId];
    const client = getState().qt_endpoint.clients[clientId];
    createOrUpdateToast(
      `Setting Passenger Details For Booking ${booking.reference}`,
      {
        toastId: bookingId,
      }
    );

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/bookings/${bookingId}/update_with_nested_attributes`,
      options,
      params: {
        booking_id: bookingId,
        booking: {
          consumers: [
            {
              first_name: client.first_name,
              last_name: client.last_name,
              passenger_type_id: 1,
            },
          ],
        },
      },
    })
      .then((result) => {
        if (isEmpty(result)) {
          return;
        }
        dispatch(
          createReservation({
            bookingId: result.id,
            values,
            clientId,
          })
        );
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId: clientId,
                  errorString: e.error,
                  type: 'updating booking client details',
                  toastId: bookingId,
                })
              );
            });
          },
        })
      );
  };
};

export const chargeOrganisationAccount = function ({
  bookingId,
  organisationClientId,
  serviceId,
  clientId,
}) {
  return (dispatch, getState) => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    const booking = getState().qt_endpoint.bookings[bookingId];

    createOrUpdateToast(`Paying For Reservation ${booking.reference}`, {
      type: toast.TYPE.INFO,
      toastId: bookingId,
    });

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/checkouts`,
      options,
      params: {
        booking_id: bookingId,
        payment: {
          till_id: Config.tillId,
          uid: uuidv4(),
          payment_type_id: Config.onAccountPaymentTypeId,
          avoid_surcharge: true,
          client_id: organisationClientId,
        },
        pay_balance: true,
      },
    })
      .then((result) => {
        if (isEmpty(result)) {
          return;
        }
        createOrUpdateToast(`Booked! ${booking.reference}`, {
          type: toast.TYPE.SUCCESS,
          toastId: bookingId,
        });
        dispatch(addBookingToService({ serviceId, bookingId }));
        dispatch(show({ bookingId: bookingId }));
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId: clientId,
                  errorString: e.error,
                  type: `charging account`,
                  toastId: bookingId,
                })
              );
            });
          },
        })
      );
  };
};

export const getBraintreeClientToken = function () {
  return (dispatch, getState) => {
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      gateway: 'braintree',
    };

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/checkouts/client_token.json`,
      options,
    })
      .then((result) => {
        return result ? result : Promise.reject(result);
      })
      .then((result) => {
        dispatch(
          setEntities({
            entities: { braintreeClientToken: result.client_token },
          })
        );
        return result.client_token;
      })
      .catch(() => {
        dispatch(
          reportAndDisplayError('Error communicating with payment service.')
        );
      });
  };
};

export const braintreePayBooking = function ({
  bookingId,
  data,
  paymentTypeId,
}) {
  return (dispatch, getState) => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    const booking = getState().qt_endpoint.bookings[bookingId];

    createOrUpdateToast(`Paying For Booking ${booking.reference}`, {
      type: toast.TYPE.INFO,
      toastId: bookingId,
    });

    const checkoutId = uuidv4();

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/checkouts`,
      options,
      params: {
        booking_id: bookingId,
        payment: {
          till_id: Config.tillId,
          uid: checkoutId,
          payment_type_id: paymentTypeId,
          avoid_surcharge: false,
        },
        ...data,
        pay_balance: true,
      },
    })
      .then((result) => {
        if (isEmpty(result)) {
          return;
        }
        return dispatch(pollCheckout({ bookingId, checkoutId }));
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId: booking.client_id,
                  errorString: e.error,
                  type: `creating payment`,
                  toastId: bookingId,
                })
              );
            });
          },
        })
      );
  };
};

export const pollCheckout = function ({
  checkoutId,
  bookingId,
  tryCount = 20,
}) {
  return (dispatch, getState) => {
    const booking = getState().qt_endpoint.bookings[bookingId];
    const options = {
      method: 'GET',
    };

    const url = new URL(`${Config.quickTravelURL}/api/checkouts/${checkoutId}`);
    return authorizedFetch(dispatch, getState, {
      url,
      options,
    })
      .then((result) => {
        if (result.successful) {
          createOrUpdateToast(`Booking ${booking.reference} has been paid.`, {
            type: toast.TYPE.SUCCESS,
            toastId: bookingId,
          });
          dispatch(show({ bookingId: bookingId }));
        } else {
          tryCount--;
          if (tryCount > 0) {
            setTimeout(
              dispatch(pollCheckout({ bookingId, checkoutId, tryCount })),
              500
            );
          } else {
            Rollbar.warning(
              `${booking.reference} excesses maximum fetching attempts.`
            );
            dispatch(
              generateClientErrorMessage({
                clientId: booking.client_id,
                errorString: `Payment system did not respond. Please contact SeaLink Rottnest Island on 1300 786 552 and quote booking reference ${booking.reference} for support.`,
                type: `paying booking`,
                toastId: bookingId,
              })
            );
          }
        }
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId: booking.client_id,
                  errorString: e.error,
                  type: `paying booking`,
                  toastId: bookingId,
                })
              );
            });
          },
        })
      );
  };
};

export const activateBooking = function ({ bookingId, serviceId, clientId }) {
  return (dispatch, getState) => {
    const booking = getState().qt_endpoint.bookings[bookingId];
    const options = {
      method: 'PUT',
    };

    const url = new URL(
      `${Config.quickTravelURL}/api/bookings/${bookingId}/activate`
    );

    return authorizedFetch(dispatch, getState, {
      url,
      options,
    })
      .then((result) => {
        if (isEmpty(result)) {
          return;
        }
        createOrUpdateToast(
          `Booking ${booking.reference} has been created, but has not yet been paid. Please pay for this booking before departure.`,
          {
            type: toast.TYPE.SUCCESS,
            toastId: bookingId,
          }
        );
        dispatch(show({ bookingId: bookingId }));
        dispatch(addBookingToService({ serviceId, bookingId }));
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId: clientId,
                  errorString: e.error,
                  type: 'activating booking',
                  toastId: bookingId,
                })
              );
            });
          },
        })
      );
  };
};

export const cancelBooking = function ({ bookingId, callback, clientId }) {
  return (dispatch, getState) => {
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };

    const booking = getState().qt_endpoint.bookings[bookingId];

    if (!withinCancellationWindow(bookingId, getState())) {
      dispatch(
        reportAndDisplayError(
          `Unable to cancel Booking ${booking.reference}. Cancellation window has closed (${Config.cancelCutoffMinutes} minutes)`,
          {
            toastId: bookingId,
          }
        )
      );

      return Promise.resolve();
    }

    createOrUpdateToast('Cancelling booking', {
      type: toast.TYPE.INFO,
      toastId: bookingId,
    });

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/bookings/${bookingId}/cancel`,
      options,
      params: {
        refund_payments: true,
      },
    })
      .then((result) => {
        if (isEmpty(result)) {
          return;
        }
        dispatch(setBookingCancelled({ bookingId }));
        createOrUpdateToast(`Booking ${booking.reference} Cancelled`, {
          type: toast.TYPE.INFO,
          toastId: bookingId,
        });
      })
      .then(() => {
        if (callback && isFunction(callback)) {
          callback();
        }
      })
      .catch(
        handleErrorStatus({
          status: 422,
          handler: (error) => {
            error.json().then((e) => {
              dispatch(
                generateClientErrorMessage({
                  clientId: clientId,
                  errorString: e.error,
                  type: 'cancelling booking',
                  toastId: bookingId,
                })
              );
            });
          },
        })
      );
  };
};

export const getBarcode = (booking) => {
  return (dispatch, getState) => {
    if (isEmpty(booking?.issued_tickets_attributes)) {
      return dispatch(
        reportAndDisplayError(
          'Sorry, your tickets have not yet been issued. Please call us on 1300 786 552 or visit the ticket office and quote your booking number.'
        )
      );
    }
    const accessToken = getState().login?.authenticatedUser?.access_token;
    return BarcodeService.barcode({
      data: booking.issued_tickets_attributes[0].reference,
      accessToken,
    }).then((imageData) => {
      if (!imageData) {
        dispatch(reportAndDisplayError('Unable to generate Check In Barcode'));
      }

      dispatch(
        setEntities({
          entities: { barcodes: { [booking.id]: imageData } },
        })
      );
    });
  };
};

const findServiceAndTripFromBookingId = (bookingId, reduxState) => {
  const bookingIdInt = parseInt(bookingId);

  const services = filter(reduxState.qt_endpoint.services, (service) => {
    return service.booking_ids.includes(bookingIdInt);
  });

  if (isEmpty(services)) {
    return [null, null];
  }

  const serviceId = services[0].id;

  const service = reduxState.qt_endpoint.services[serviceId];
  const route = reduxState.qt_endpoint.routes[service.routeId];

  if (!route?.trips || !service) {
    return [null, null];
  }

  const trip = route.trips[service.trip_id];

  return [service, trip];
};

export const withinCancellationWindow = (bookingId, reduxState) => {
  if (isEmpty(reduxState.qt_endpoint.bookings[bookingId])) {
    return false;
  }

  const [service, trip] = findServiceAndTripFromBookingId(
    bookingId,
    reduxState
  );

  if (!service || !trip) {
    return false;
  }

  return serviceWithinWindow({
    service,
    trip,
    limit: Config.cancelCutoffMinutes,
  });
};

export const withinBookingWindow = (serviceId, reduxState) => {
  const service = reduxState.qt_endpoint.services[serviceId];
  const route = reduxState.qt_endpoint.routes[service.routeId];
  const trip = route?.trips[service.trip_id];

  return serviceWithinWindow({
    service,
    trip,
    limit: Config.bookingCutoffMinutes,
  });
};

export const getClientBookings = ({ clientId, page = 1 }) => {
  return (dispatch, getState) => {
    const profile = getState().login.profile;
    if (!profile) {
      return;
    }

    if (!hasManagerRight(profile) && profile.client_id !== parseInt(clientId)) {
      dispatch(reportAndDisplayError('Unauthorised client booking request'));
      return;
    }

    if (page === 1) {
      dispatch(fetchBookingsStarted({ clientId }));
    }

    const url = new URL(`${Config.quickTravelURL}/api/bookings`);
    url.searchParams.append('client_id', clientId);
    url.searchParams.append('full_response', true);
    url.searchParams.append('page', page);
    url.searchParams.append('limit', perPageLimit);
    url.searchParams.append(
      'first_travel_date[from]',
      moment().format(reverseDateFormat)
    );
    url.searchParams.append(
      'first_travel_date[to]',
      moment('9999-01-01').format(reverseDateFormat)
    );

    return authorizedFetch(dispatch, getState, {
      url,
      options: {
        method: 'GET',
      },
    })
      .then((jsonData) => {
        if (isEmpty(jsonData)) {
          dispatch(fetchBookingsFinished({ clientId }));
          return { entities: { bookings: {} } };
        }

        if (jsonData.length === perPageLimit) {
          // Fetch the next page
          dispatch(getClientBookings({ clientId, page: page + 1 }));
        } else {
          // Finished Fetching
          dispatch(fetchBookingsFinished({ clientId }));
        }

        return normalize(jsonData, [booking]);
      })
      .then((normalizedData) => {
        const bookings = normalizedData.entities.bookings;

        dispatch(setEntities({ entities: { bookings } }));
      })
      .catch((error) => {
        dispatch(fetchBookingsError({ clientId }));
        dispatch(reportAndDisplayError(error));
      });
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index,
  show,
  cancelBooking,
  chargeOrganisationAccount,
  createBookingAndReservation,
  getBarcode,
  updateBookingAttributes,
  activateBooking,
  withinCancellationWindow,
  withinBookingWindow,
  getBraintreeClientToken,
  braintreePayBooking,
  getClientBookings,
  pollCheckout,
};
