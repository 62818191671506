import { createAction } from '@reduxjs/toolkit';

const setServices = createAction('services/succeeded');
const fetchBookingsStarted = createAction('bookings/fetchStarted');
const fetchBookingsFinished = createAction('bookings/fetchFinished');
const fetchBookingsError = createAction('bookings/fetchError');
const fetchServicesStarted = createAction('services/fetchStarted');
const fetchServicesFinished = createAction('services/fetchFinished');
const fetchServicesError = createAction('services/fetchError');
const setEntities = createAction('QTEndpoint/setEntities');
const addBookingToService = createAction('QTEndpoint/addBookingToService');
const resetEntities = createAction('QTEndpoint/resetEntities');
const setBookingCancelled = createAction('QTEndpoint/setBookingCancelled');

export const started = 'STARTED';
export const finished = 'FINISHED';
export const errorStatus = 'ERROR';

export {
  setServices,
  fetchBookingsStarted,
  fetchBookingsFinished,
  fetchBookingsError,
  setEntities,
  addBookingToService,
  resetEntities,
  setBookingCancelled,
  fetchServicesStarted,
  fetchServicesFinished,
  fetchServicesError,
};
