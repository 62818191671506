import React, { useEffect } from 'react';
import { DayCarousel } from 'components/day_carousel';

import QuickTravelAPI from 'api/quick_travel_api';
import { useDispatch } from 'react-redux';
import {
  useOrganisationId,
  useAuthenticatedUser,
  useGetRoutesApiRight,
} from 'redux/selectors/organisations';

import Config from 'config/config';

export function RoutesTable({ routeIds, days, date }) {
  const dispatch = useDispatch();
  const user = useAuthenticatedUser();
  const organisationId = useOrganisationId();
  const hasGetRoutesApiRight = useGetRoutesApiRight();

  useEffect(() => {
    if (!hasGetRoutesApiRight) {
      return;
    }
    dispatch(QuickTravelAPI.Routes.index({ routeIds }));
    routeIds.forEach((routeId) => {
      dispatch(QuickTravelAPI.Trips.getTripsForRoute({ routeId: routeId }));
    });
  }, [dispatch, routeIds, user, hasGetRoutesApiRight]);

  useEffect(() => {
    if (organisationId) {
      dispatch(QuickTravelAPI.Bookings.index({ organisationId, date }));
    }
  }, [organisationId, dispatch, date, user]);

  return (
    <div className="routes-table">
      {Config.segments.map((segment) => {
        return (
          <DayCarousel
            key={`Route--${segment.id}`}
            segment={segment}
            routeId={segment.route_id}
            days={days}
            date={new Date(date)}
          />
        );
      })}
    </div>
  );
}
