import React from 'react';
import { logout } from 'redux/actions/login';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons';

export function LogoutButton() {
  const dispatch = useDispatch();
  const { authenticatedUser } = useSelector((state) => state.login);

  return isEmpty(authenticatedUser) ? null : (
    <button className="signout-btn" onClick={() => dispatch(logout())}>
      Sign out
      <FontAwesomeIcon className="signout-btn-icon" icon={faSignOutAlt} />
    </button>
  );
}
