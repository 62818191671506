import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import 'stylesheets/global.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import store from './redux/store';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { PrivateRoute } from 'components/routing';

import LoginPage from 'pages/login';
import Dashboard from 'pages/dashboard';
import { Show as ServiceShowPage } from 'pages/services';
import { Show as BookingShowPage, FutureBookings } from 'pages/bookings';
import { Pay as BookingPayPage } from 'pages/bookings';
import { BookingCreator } from 'components/bookings/creator';

import { ModalContainer, ModalRoute } from 'react-router-modal';
import { handleBookingUpdates } from 'helpers/bookings_helper';

import { ToastContainer } from 'react-toastify';

import { initRollbar } from 'utils/rollbar';
import { initDataDog } from 'utils/datadog';
import Config from 'config/config';

import { Security, LoginCallback } from '@okta/okta-react';
import { oktaAuth, restoreOriginalUri } from './api/okta_service';

initRollbar();
initDataDog();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer className="toast" />
      <Router>
        <Route
          exact
          path="/"
          render={() => {
            return (
              <Redirect
                to={{
                  pathname: '/dashboard',
                }}
              />
            );
          }}
        />
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Route path="/login" component={LoginPage} />
          <Route path="/callback" component={LoginCallback} />
        </Security>
        <Switch>
          <ModalRoute
            exact
            path="/dashboard/bookings/new"
            parentPath="/dashboard"
            component={BookingCreator}
            className="modal"
            inClassName="modal-in"
            outClassName="modal-out"
            backdropClassName="modal-backdrop"
            backdropInClassName="modal-backdrop-in"
            backdropOutClassName="modal-backdrop-out"
            outDelay={150}
            props={{ handleSubmit: handleBookingUpdates }}
          />
          <ModalRoute
            exact
            path="/dashboard/service/:service_id/bookings/new"
            parentPath="/dashboard"
            className="modal"
            inClassName="modal-in"
            outClassName="modal-out"
            backdropClassName="modal-backdrop"
            backdropInClassName="modal-backdrop-in"
            backdropOutClassName="modal-backdrop-out"
            outDelay={150}
            component={BookingCreator}
            props={{ handleSubmit: handleBookingUpdates }}
          />
          <ModalRoute
            path="/dashboard/bookings/:booking_id"
            parentPath="/dashboard"
            className="modal"
            inClassName="modal-in"
            outClassName="modal-out"
            backdropClassName="modal-backdrop"
            backdropInClassName="modal-backdrop-in"
            backdropOutClassName="modal-backdrop-out"
            outDelay={150}
            component={BookingShowPage}
          />
          <ModalRoute
            path="/dashboard/pay_booking/:booking_id"
            parentPath={(match) =>
              `/dashboard/bookings/${match.params.booking_id}`
            }
            className="modal"
            inClassName="modal-in"
            outClassName="modal-out"
            backdropClassName="modal-backdrop"
            backdropInClassName="modal-backdrop-in"
            backdropOutClassName="modal-backdrop-out"
            outDelay={150}
            component={BookingPayPage}
          />
          <ModalRoute
            path="/dashboard/service/:service_id"
            parentPath="/dashboard"
            className="modal"
            inClassName="modal-in"
            outClassName="modal-out"
            backdropClassName="modal-backdrop"
            backdropInClassName="modal-backdrop-in"
            backdropOutClassName="modal-backdrop-out"
            outDelay={150}
            component={ServiceShowPage}
          />
          <ModalRoute
            path="/dashboard/future_bookings"
            parentPath="/dashboard"
            className="modal"
            inClassName="modal-in"
            outClassName="modal-out"
            backdropClassName="modal-backdrop"
            backdropInClassName="modal-backdrop-in"
            backdropOutClassName="modal-backdrop-out"
            outDelay={150}
            component={FutureBookings}
          />
        </Switch>
        <PrivateRoute path="/dashboard">
          <Dashboard routeIds={Config.routeIDs} date={new Date()} />
        </PrivateRoute>
        <ModalContainer />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
