// Stubbed Booking Page
import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { longDateFormat } from 'utils/time_formats';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import classNames from 'classnames';

import QuickTravelAPI from 'api/quick_travel_api';

import { generateImageUrl } from 'utils/url_generator';
import { useReservationServiceName } from 'redux/selectors/segments';
import {
  useProfile,
  useOrganisationManager,
  useSelfManagedStaff,
} from 'redux/selectors/organisations';
import {
  useCanCancelBooking,
  useBookingServices,
} from 'redux/selectors/services';

import { Link } from 'react-router-dom';

import { isBookingStub, bookingBelongsTo } from 'helpers/bookings_helper';
import { isEmpty } from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';

import { renderHeading } from 'components/modals/heading';
import { BookingService } from 'pages/bookings/service';

export function Show({ match, closeModal, history }) {
  const dispatch = useDispatch();

  // Fetch booking information
  const bookingId = match.params.booking_id;
  const booking = useSelector((state) => {
    const obj = state.qt_endpoint.bookings[bookingId];

    if (isBookingStub(obj)) {
      return undefined;
    }

    return obj;
  });

  useEffect(() => {
    if (isBookingStub(booking)) {
      // This is a booking stub, fetch the whole booking
      dispatch(QuickTravelAPI.Bookings.show({ bookingId, closeModal }));
    }
  }, [booking, dispatch, bookingId, closeModal]);

  const reservation = useSelector((state) => {
    if (!booking) {
      return undefined;
    }

    return booking.reservations_attributes[0];
  });

  const route = useSelector((state) => {
    if (!reservation) {
      return undefined;
    }

    return state.qt_endpoint.routes[reservation.route_id];
  });

  const barcode = useSelector((state) => {
    if (!booking) {
      return undefined;
    }

    if (!state.qt_endpoint.barcodes) {
      return '';
    }

    return state.qt_endpoint.barcodes[booking.id];
  });

  const canCancelService = useCanCancelBooking(bookingId);
  const bookingServices = useBookingServices(bookingId);

  const segmentName = useReservationServiceName(reservation);
  const profile = useProfile();
  const isOrganisationManager = useOrganisationManager();
  const isSelfManagedStaff = useSelfManagedStaff();
  const [cancelArmed, setCancelArmed] = useState(false);
  const [bookingPaid, setBookingPaid] = useState(false);

  useEffect(() => {
    setBookingPaid(booking?.balance_in_cents === 0);
  }, [booking]);

  // Absolutely making sure we have the relevant services for this date
  // so that the cancel booking button can be set correctly
  useEffect(() => {
    if (!isBookingStub(booking)) {
      dispatch(QuickTravelAPI.Services.getServicesForBooking(booking));
    }
  }, [booking, dispatch]);

  useEffect(() => {
    if (
      reservation &&
      bookingBelongsTo({ clientId: profile?.client_id, booking }) &&
      bookingPaid &&
      !barcode
    ) {
      dispatch(QuickTravelAPI.Bookings.getBarcode(booking));
    }
  }, [reservation, dispatch, profile, barcode, booking, bookingPaid]);

  const [logToDataDog, setLogToDataDog] = useState(false);

  // TODO: TT-9037 revert this PR
  const stateDump = useSelector((state) => {
    if (logToDataDog) {
      return state.qt_endpoint;
    }
    return null;
  });

  useEffect(() => {
    if (!isEmpty(stateDump) && logToDataDog !== 'logged') {
      datadogLogs.logger.error('Cannot load booking', { stateDump, profile });
      setLogToDataDog('logged');
    }
  }, [stateDump, logToDataDog, profile]);

  if (!booking) {
    return (
      <div>
        <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
        Loading Booking&hellip;
      </div>
    );
  }

  if (!reservation || !route) {
    if (process.env.REACT_APP_DATADOG_ENABLED && !logToDataDog) {
      setLogToDataDog(true);
    }
    return <div> Error: Cannot load Booking...</div>;
  }

  function departureTimes() {
    return bookingServices
      .sort((a, b) =>
        moment(a.departure_time).isBefore(moment(b.departure_time)) ? -1 : 1
      )
      .map((service, index) => {
        return <BookingService service={service} key={index} />;
      });
  }

  function handleCancel() {
    if (canCancelService) {
      dispatch(
        QuickTravelAPI.Bookings.cancelBooking({
          bookingId: booking.id,
          callback: closeModal,
        })
      );
    }
  }

  function renderCheckInInfo() {
    if (bookingBelongsTo({ clientId: profile?.client_id, booking })) {
      return (
        <div className="row justify-content-start">
          <div className="col-12 col-md-6 mb-4">
            <img
              src={generateImageUrl(profile?.picture)}
              alt="Profile"
              className="profile-picture"
            />
          </div>
          <div className="col-12 col-md-6 mb-4">{renderBarcode()}</div>
        </div>
      );
    } else {
      if (booking.balance_in_cents > 0) {
        return (
          <div className="booking-customer-info">
            <span>Booking Unpaid</span>
          </div>
        );
      } else {
        return null;
      }
    }
  }

  function renderBarcode() {
    if (!bookingPaid) {
      return (
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Booking has not been paid for</h4>
            <p>Please pay below or arrive early and pay before departure.</p>
            <Link
              to={`/dashboard/pay_booking/${booking.id}`}
              className="btn btn-primary d-block d-md-inline-block"
            >
              Pay Booking
            </Link>
          </div>
        </div>
      );
    }
    if (barcode) {
      return (
        <img
          src={`data:image/png;base64,${barcode}`}
          className="barcode"
          alt="Booking Checkin Barcode"
        />
      );
    } else {
      return <span></span>;
    }
  }

  function renderCancelButton() {
    if (isOrganisationManager || isSelfManagedStaff) {
      if (cancelArmed) {
        return (
          <div className="row justify-content-start">
            <div className="col-6">
              <div
                className="btn btn-secondary d-block w-100"
                onClick={() => {
                  setCancelArmed(false);
                }}
              >
                No, do not cancel booking
              </div>
            </div>
            <div className="col-6">
              <button
                className={classNames('btn btn-danger w-100', {
                  'btn-disabled': !canCancelService,
                  disabled: !canCancelService,
                })}
                onClick={handleCancel}
              >
                Yes, cancel booking
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <button
            className={classNames('btn btn-danger w-100', {
              'btn-disabled': !canCancelService,
              disabled: !canCancelService,
            })}
            onClick={() => {
              if (canCancelService) {
                setCancelArmed(true);
              }
            }}
          >
            {canCancelService
              ? 'Cancel Booking'
              : 'Cancellation window has closed'}
          </button>
        );
      }
    }
  }

  return (
    <div>
      {renderHeading({ closeModal, history })}

      <h2 className="mb-3">{segmentName}</h2>
      <h3 className="mb-3">
        {moment(booking.first_travel_date._value).format(longDateFormat)}
        <span className="d-block font-weight-light">{departureTimes()}</span>
      </h3>
      <div className="booking-customer-info">
        <span>Booking Reference: </span>
        {booking.reference}
      </div>
      <div className="booking-customer-info mb-4">
        <span>Name: </span>
        {booking.customer_contact_name}
      </div>
      {renderCheckInInfo()}
      <div className="row">
        <div className="col">{renderCancelButton()}</div>
      </div>
    </div>
  );
}

export default Show;
