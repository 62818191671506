import Config from 'config/config';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { intersection, pickBy, filter } from 'lodash';

import QuickTravelAPI from 'api/quick_travel_api';
import { serviceWithinWindow } from 'helpers/services_helper';

export function useService(serviceId) {
  const service = useSelector((state) => {
    if (state.qt_endpoint.services[serviceId] === undefined) return undefined;
    else return state.qt_endpoint.services[serviceId];
  });

  return service;
}

export function useBookingServices(bookingId) {
  const allServices = useSelector((state) => state.qt_endpoint.services);
  const booking = useSelector((state) => state.qt_endpoint.bookings[bookingId]);
  const services = useSelector((state) => {
    if (!booking || !allServices || !booking.reservations_attributes) {
      return [];
    }

    return filter(allServices, (service) =>
      booking.reservations_attributes[0]?.service_ids?.includes(service.id)
    );
  });

  return services;
}

export function useServiceBookingsIds(service) {
  const [serviceBookingIds, setServiceBookingIds] = useState([]);
  const [activeServiceBookingIds, setActiveServiceBookingIds] = useState([]);
  const allBookings = useSelector((state) => state.qt_endpoint.bookings);

  useEffect(() => {
    if (service && allBookings) {
      const orgBookingIDs = Object.keys(allBookings).map((i) => parseInt(i));

      const serviceBookingIds = intersection(
        service.booking_ids,
        orgBookingIDs
      );
      setServiceBookingIds(serviceBookingIds);

      const activeBookingIDs = Object.keys(
        pickBy(allBookings, function (booking, id) {
          return booking.state === 'active';
        })
      ).map((i) => parseInt(i));

      setActiveServiceBookingIds(
        intersection(serviceBookingIds, activeBookingIDs)
      );
    }
  }, [service, allBookings]);

  return { activeServiceBookingIds, serviceBookingIds, allBookings };
}

export function useCanCancelBookingsOnService(serviceId) {
  return useSelector((state) => {
    const service = state.qt_endpoint.services[serviceId];
    if (!service) {
      return false;
    }

    const route = state.qt_endpoint.routes[service.routeId];

    if (!route) {
      return false;
    }

    const trip = route.trips[service.trip_id];

    return serviceWithinWindow({
      service,
      trip,
      limit: Config.cancelCutoffMinutes,
    });
  });
}

export function useCanMakeBookingsOnService(serviceId) {
  return useSelector((state) => {
    const service = state.qt_endpoint.services[serviceId];
    if (!service) {
      return false;
    }

    const route = state.qt_endpoint.routes[service.routeId];

    if (!route) {
      return false;
    }

    const trip = route.trips[service.trip_id];

    return serviceWithinWindow({
      service,
      trip,
      limit: Config.bookingCutoffMinutes,
    });
  });
}

export function useCanCancelBooking(bookingId) {
  return useSelector((state) => {
    return QuickTravelAPI.Bookings.withinCancellationWindow(bookingId, state);
  });
}
