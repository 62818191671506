import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PassengerList } from 'components/passenger_list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import {
  useService,
  useCanCancelBookingsOnService,
  useCanMakeBookingsOnService,
} from 'redux/selectors/services';

import moment from 'moment';
import { longDateFormat, serviceTimeFormat } from 'utils/time_formats';
import { useServiceName } from 'redux/selectors/segments';
import { isEmpty } from 'lodash';

import QuickTravelAPI from 'api/quick_travel_api';
import { renderHeading } from 'components/modals/heading';
// Dummy thunk
const fetchService = (serviceId) => {
  return (dispatch) =>
    dispatch({ type: 'api/fetchService', payload: serviceId });
};

export function Show({ match, closeModal, history }) {
  const serviceId = match.params.service_id;
  const service = useService(serviceId);

  const [cancelArmed, setCancelArmed] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchService(serviceId));
  }, [serviceId, dispatch]);

  const route = useSelector((state) => {
    if (!service) {
      return undefined;
    }

    return state.qt_endpoint.routes[service.routeId];
  });

  const [trip, setTrip] = useState();
  useEffect(() => {
    if (isEmpty(service) || isEmpty(route)) {
      return undefined;
    }

    setTrip(route.trips[service.trip_id]);
  }, [service, route]);

  const [selectedBookings, setSelectedBookings] = useState({});
  const [selectedBookingIds, setSelectedBookingIds] = useState([]);

  useEffect(() => {
    setSelectedBookingIds(
      Object.keys(selectedBookings).filter((key) => selectedBookings[key])
    );
  }, [selectedBookings]);

  useEffect(() => {
    if (selectedBookingIds.length === 0) {
      setCancelArmed(false);
    }
  }, [selectedBookingIds]);

  const canCancelService = useCanCancelBookingsOnService(serviceId);
  const canBookService = useCanMakeBookingsOnService(serviceId);

  const segmentName = useServiceName(service);
  if (!service) {
    return (
      <div>
        <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
        Loading Service&hellip;
      </div>
    );
  }

  if (!route) {
    return (
      <div>
        <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
        Loading Route Details&hellip;
      </div>
    );
  }

  function handleCancel() {
    selectedBookingIds.forEach((bookingId) => {
      dispatch(
        QuickTravelAPI.Bookings.cancelBooking({
          bookingId,
          callback: closeModal,
        })
      );
    });
    setSelectedBookings({});
  }

  function renderActionButton() {
    if (cancelArmed) {
      const message =
        selectedBookingIds.length > 1
          ? 'Yes, Cancel selected bookings'
          : 'Yes, Cancel selected booking';

      const abortMessage =
        selectedBookingIds.length > 1
          ? 'No, do not cancel bookings'
          : 'No, do not cancel booking';

      return (
        <div className="row justify-content-start">
          <div className="col-6">
            <div
              className="btn btn-secondary d-block w-100"
              onClick={() => {
                setCancelArmed(false);
                setSelectedBookings({});
              }}
            >
              {abortMessage}
            </div>
          </div>
          <div className="col-6">
            <div
              className="btn btn-danger d-block w-100"
              onClick={handleCancel}
            >
              {message}
            </div>
          </div>
        </div>
      );
    } else if (selectedBookingIds.length >= 1) {
      if (!canCancelService) {
        return (
          <div className="btn btn-danger d-block btn-disabled disabled">
            Cancellation window has closed
          </div>
        );
      }

      const message =
        selectedBookingIds.length > 1
          ? 'Cancel selected bookings'
          : 'Cancel selected booking';

      return (
        <div
          className="btn btn-danger d-block"
          onClick={() => setCancelArmed(true)}
        >
          {message}
        </div>
      );
    }

    if (!canBookService && !canCancelService) {
      return (
        <div className="btn btn-secondary d-block btn-disabled disabled">
          No further changes can be made to this service
        </div>
      );
    }

    return (
      <Link to={`/dashboard/service/${serviceId}/bookings/new`}>
        <div className="btn btn-secondary d-block">
          {canCancelService
            ? 'Add or remove staff from service'
            : 'Add Staff to Service'}
        </div>
      </Link>
    );
  }

  function renderDepartureTime() {
    if (trip) {
      return moment(trip.departure_time).utc().format(serviceTimeFormat);
    }
  }

  return (
    <div>
      {renderHeading({ closeModal, history })}
      <h2>{segmentName}</h2>
      <h3 className="mb-4">
        {moment(service.date).format(longDateFormat)}
        <span className="d-block font-weight-light">
          {renderDepartureTime()}
        </span>
      </h3>
      <PassengerList
        service={service}
        onSelectionChange={(selectedIds) => {
          setSelectedBookings(selectedIds);
        }}
        selected={selectedBookings}
      />
      {renderActionButton()}
    </div>
  );
}
