import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export function useAuthenticatedUser() {
  return useSelector((state) => state.login.authenticatedUser);
}

export function useProfile() {
  return useSelector((state) => state.login.profile);
}

export function useGetRoutesApiRight() {
  return useSelector((state) =>
    state.login.profile?.rights?.includes('api/routes#read')
  );
}

export function useOrganisationId() {
  const [organisationId, setOrganisationId] = useState();
  const profile = useProfile();

  useEffect(() => {
    setOrganisationId((profile?.organisation_ids || [])[0]);
  }, [profile]);

  return organisationId;
}

export function useOrganisationManager() {
  const [isOrganisationManager, setIsOrganisationManager] = useState(undefined);
  const profile = useProfile();

  useEffect(() => {
    setIsOrganisationManager(hasManagerRight(profile));
  }, [profile]);

  return isOrganisationManager;
}

export function useSelfManagedStaff() {
  const [isSelfManagedStaff, setIsSelfManagedStaff] = useState(undefined);
  const profile = useProfile();

  useEffect(() => {
    setIsSelfManagedStaff(hasStaffSelfManagementRight(profile));
  }, [profile]);

  return isSelfManagedStaff;
}

export function hasManagerRight(profile) {
  const managerRightName = 'external_organisation_manager';
  if (isEmpty(profile)) {
    return undefined;
  }

  if (profile && profile.rights) {
    return profile.rights?.includes(managerRightName);
  } else {
    return false;
  }
}

export function hasStaffSelfManagementRight(profile) {
  const staffSelfManagementRight = 'external_organisation_staff_self_managed';

  if (profile && profile.rights) {
    return profile.rights?.includes(staffSelfManagementRight);
  } else {
    return false;
  }
}

export function useOrganisationClientId() {
  const organisationId = useOrganisationId();

  return useSelector((state) => {
    if (!organisationId || !state?.qt_endpoint?.organisations) {
      return undefined;
    }
    return state.qt_endpoint.organisations[organisationId]?.client_id;
  });
}
