import { logout } from 'redux/actions/login';
import { reportError } from 'redux/actions/errors';

import { toast } from 'react-toastify';

const unauthorizedToastId401 = 'error-401';
const unauthorizedToastId403 = 'error-403';

const createOrUpdateToast = (message, options = {}) => {
  const { toastId } = options;

  const defaultToastOptions = {
    pauseOnFocusLoss: false,
  };

  if (toastId && toast.isActive(toastId)) {
    return toast.update(toastId, {
      ...defaultToastOptions,
      ...options,
      render: message,
    });
  } else {
    return toast(message, { ...defaultToastOptions, ...options });
  }
};

const reportAndDisplayError = (
  errorMessage,
  options = {},
  autoClose = 60000
) => {
  return (dispatch) => {
    const defaultToastErrorOptions = {
      autoClose,
      type: toast.TYPE.ERROR,
      pauseOnFocusLoss: true,
    };

    const toastOptions = { ...defaultToastErrorOptions, ...options };

    createOrUpdateToast(errorMessage, toastOptions);
    dispatch(reportError(errorMessage));
  };
};

const generateClientErrorMessage = ({
  clientId,
  errorString,
  type,
  toastId,
}) => {
  return (dispatch, getState) => {
    const client = getState().qt_endpoint.clients[clientId];
    const errorMessage = `Error ${type} for ${client.first_name} ${client.last_name}. ${errorString}`;
    return reportAndDisplayError(errorMessage, { toastId }, false)(dispatch);
  };
};

const handleErrorStatus = ({ status, handler }) => (error) => {
  if (error.status === status) {
    handler(error);
  } else {
    throw error;
  }
};

const authorizedFetch = (dispatch, getState, { url, options, params }) => {
  const { headers, ...opts } = options || {};

  const { authenticatedUser } = getState().login;
  const access_token = authenticatedUser?.access_token;

  return fetch(url, {
    ...opts,
    headers: { ...headers, Authorization: `Bearer ${access_token}` },
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      if (response.status === 401) {
        dispatch(logout());
        dispatch(
          reportAndDisplayError(
            'Unauthorized network request.  Please log in again',
            { toastId: unauthorizedToastId401 }
          )
        );
        return null;
      } else if (response.status === 403) {
        dispatch(
          reportAndDisplayError(
            'You do not have permission to perform this action.',
            { toastId: unauthorizedToastId403 }
          )
        );
        return Promise.reject(response);
      } else if (response.status === 422) {
        return Promise.reject(response);
      } else {
        dispatch(reportAndDisplayError('Unable to parse response'));
        return null;
      }
    }
  });
};

export {
  authorizedFetch,
  reportAndDisplayError,
  createOrUpdateToast,
  generateClientErrorMessage,
  handleErrorStatus,
};
