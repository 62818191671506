import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { filter, find, sortBy, isEmpty } from 'lodash';
import QuickTravelAPI from 'api/quick_travel_api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinnerThird,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  useProfile,
  useOrganisationManager,
} from 'redux/selectors/organisations';
import { BookingCard } from './booking_card';
import {
  isBookingStub,
  bookingBelongsTo,
  tripDetailsFromBooking,
} from 'helpers/bookings_helper';
import Select from 'react-select';

import { started, errorStatus } from 'redux/actions/qt_endpoint';
import { sortTimeFormat } from 'utils/time_formats';

import { renderHeading } from 'components/modals/heading';

export function FutureBookings({ closeModal, history, location }) {
  const myBookingList = location?.query?.myBookingList;
  const dispatch = useDispatch();
  const profile = useProfile();
  const isOrganisationManager = useOrganisationManager();

  const [clientId, setClientId] = useState();
  const bookings = useSelector((state) => {
    return sortBy(
      filter(
        state.qt_endpoint.bookings,
        (booking) =>
          bookingBelongsTo({ clientId, booking }) &&
          booking.state === 'active' &&
          bookingIsInFuture(booking)
      ),
      [
        (booking) => moment(booking.first_travel_date?._value),
        (booking) => tripDetailsFromBooking(booking)?.departureTimeSort,
      ]
    );
  });

  function bookingIsInFuture(booking) {
    const bookingDate = moment(booking.first_travel_date?._value);
    const bookingTimeFormatted = moment(
      tripDetailsFromBooking(booking)?.departureTimeSort
    );
    return (
      bookingDate.isAfter() ||
      (bookingDate.isSame(Date(), 'day') &&
        bookingTimeFormatted.isAfter(moment().format(sortTimeFormat)))
    );
  }

  const bookingLoadState = useSelector((state) => {
    return clientId === undefined
      ? null
      : state.qt_endpoint.api.bookings[clientId];
  });

  const clients = useSelector((state) => {
    return Object.values(state.qt_endpoint.clients).map((subject) => {
      return {
        value: subject.client_id,
        label: `${subject.first_name} ${subject.last_name}`,
      };
    });
  });

  function clientSelectValue() {
    return clients.find(
      (option) => parseInt(option.value) === parseInt(clientId)
    );
  }

  const client = useSelector((state) => {
    if (clientId) {
      return (
        find(
          state.qt_endpoint.clients,
          (obj) => parseInt(obj.client_id) === parseInt(clientId)
        ) || {}
      );
    }
    return {};
  });

  useEffect(() => {
    if (clientId) {
      dispatch(QuickTravelAPI.Bookings.getClientBookings({ clientId }));
    }
  }, [clientId, profile, dispatch]);

  useEffect(() => {
    if (isOrganisationManager === false || myBookingList) {
      setClientId(profile?.client_id);
    }
  }, [isOrganisationManager, profile, myBookingList]);

  function renderBookings() {
    if (isEmpty(client)) {
      return;
    }

    if (bookingLoadState === started) {
      return (
        <div>
          <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
          Loading Bookings&hellip;
        </div>
      );
    }

    if (bookingLoadState === errorStatus) {
      return (
        <div>
          <FontAwesomeIcon className="mr-2" icon={faExclamationCircle} />
          Error loading bookings for passenger.
        </div>
      );
    }

    if (isEmpty(bookings)) {
      return <div>No future bookings.</div>;
    }

    return bookings.map((booking) => {
      if (isBookingStub(booking)) {
        return (
          <div key={booking.id} data-testid={`booking-${booking.id}`}>
            <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
            Loading Booking&hellip;
          </div>
        );
      }

      return (
        <BookingCard
          booking={booking}
          key={booking.id}
          data-testid={`booking-${booking.id}`}
        />
      );
    });
  }

  function renderClientSelector() {
    if (isOrganisationManager && !myBookingList) {
      return (
        <div className="mb-4">
          <h1>Future Bookings</h1>
          <Select
            options={clients}
            value={clientSelectValue()}
            onChange={(option) => setClientId(option?.value)}
            isMulti={false}
            className={'select-field'}
            closeMenuOnSelect={true}
            isClearable={true}
            data-testid="future-bookings-client-select"
          />
        </div>
      );
    }
  }

  function renderHeader() {
    if (isOrganisationManager && isEmpty(client)) {
      return null;
    }

    if (isEmpty(client)) {
      return (
        <div className="mb-4">
          <FontAwesomeIcon className="mr-2" icon={faSpinnerThird} spin />
          Loading Client Details&hellip;
        </div>
      );
    }

    return (
      <h2 className="mb-4">
        Future bookings for {client.first_name} {client.last_name}
      </h2>
    );
  }

  return (
    <div>
      {renderHeading({ closeModal, history })}
      {renderClientSelector()}
      {renderHeader()}
      {renderBookings()}
    </div>
  );
}

export default FutureBookings;
