import React from 'react';
import { LogoutButton } from 'components/login';
import sealinkLogo from 'assets/images/sealink-rottnest-logo.svg';
import sealinkLogoBTSignoff from 'assets/images/sealink-rottnest-island-logo_BT-signoff.svg';
import { useProfile } from 'redux/selectors/organisations';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ logout }) {
  const profile = useProfile();

  const renderWelcomeMessage = () => {
    if (profile) {
      return (
        <div className="header-welcome-message">
          {profile && `Welcome, ${profile.name}`}
        </div>
      );
    }
    return null;
  };

  return (
    <header>
      <img
        className="d-md-none header-logo-sealink"
        src={sealinkLogo}
        alt="SeaLink Rottnest Island"
      />
      <img
        className="d-none d-md-flex header-logo-sealink-bt"
        src={sealinkLogoBTSignoff}
        alt="SeaLink Rottnest Island"
      />
      <div className="header-logo-utilities">
        {renderWelcomeMessage()}
        <LogoutButton />
      </div>
    </header>
  );
}
