import Config from 'config/config';
import { isEmpty } from 'lodash';
import { authorizedFetch, reportAndDisplayError } from './utils';
import { setEntities } from 'redux/actions/qt_endpoint';
import { normalize, trip } from './schemas';

export const getTripsForRoute = function ({ routeId }) {
  return (dispatch, getState) => {
    const options = {
      method: 'GET',
    };

    return authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/routes/${routeId}/trips`,
      options,
    })
      .then((jsonData) => {
        if (isEmpty(jsonData)) {
          return { entities: { trips: {} } };
        }
        return normalize(jsonData, [trip]);
      })
      .then((normalizedData) => {
        const trips = normalizedData.entities.trips;
        const mockRoute = { [routeId]: { trips: trips } };
        dispatch(setEntities({ entities: { routes: mockRoute } }));
      })
      .catch((error) => {
        dispatch(reportAndDisplayError(error));
      });
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getTripsForRoute };
