import Config from 'config/config';

import { setProfile } from 'redux/actions/login';
import { authorizedFetch, reportAndDisplayError } from './utils';

const me = () => {
  return (dispatch, getState) => {
    const options = {
      method: 'GET',
    };

    authorizedFetch(dispatch, getState, {
      url: `${Config.quickTravelURL}/api/me`,
      options,
    })
      .then((profile) => {
        dispatch(setProfile(profile));
      })
      .catch((error) => {
        dispatch(reportAndDisplayError(error));
      });
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { me };
